import { Children, cloneElement, useState, useEffect, useRef } from "react";
import { incode, incodeFlowId } from "../../utils/http";
import loadingicon from "../../images/loadingicon.gif";

function Pasos({ children, pasoActual }) {
  return (
    <>
      {Children.map(
        children,
        (child, index) => pasoActual === index && cloneElement(child)
      )}
    </>
  );
}


function FrontId({ session, onSuccess, onError }) {
  const containerRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("front", containerRef.current, {
      onSuccess,
      onError: onError,
      token: session,
      numberOfTries: -1,
      showTutorial: true,
      disableFullScreen: true,
      //showCustomCameraPermissionScreen: true,
      //showDoublePermissionsRequest: true,
    });

    isMounted.current = true;
  }, [onSuccess, onError, session]);

  return <div className="incode_cont" ref={containerRef}></div>;
}

function BackId({ session, onSuccess, onError }) {
  const containerRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("back", containerRef.current, {
      onSuccess,
      onError: onError,
      token: session,
      numberOfTries: -1,
      showTutorial: true,
      disableFullScreen: true,
      //showCustomCameraPermissionScreen: true,
      //showDoublePermissionsRequest: true,
    });

    isMounted.current = true;
  }, [onSuccess, onError, session]);

  return <div className="incode_cont" ref={containerRef}></div>;
}

function ProcessId({ session, onSuccess }) {
  useEffect(() => {
    incode.processId({ token: session.token }).then(() => {
      onSuccess();
    });
  }, [onSuccess, session]);

  return (
    <div className="flex_centrado">
      <div>
        <div className="flex_centrado">
          <img className="loading_lector_INE" src={loadingicon} alt="" />
        </div>
        <h2 className="margintop_1em">Procesando informaci�n...</h2>
      </div>
    </div>
  );
}

function Selfie({ session, onSuccess, onError }) {
  const containerRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("selfie", containerRef.current, {
      onSuccess: () => {
        onSuccess(session);
      },
      onError: onError,
      token: session,
      numberOfTries: 3,
      showTutorial: true,
      disableFullScreen: true,
      //showCustomCameraPermissionScreen: true,
      //showDoublePermissionsRequest: true,
    });
    isMounted.current = true;
  }, [onSuccess, onError, session]);

  return <div className="incode_cont" ref={containerRef}></div>;
}

function Error({onClose}) {
  useEffect(()=>{
    setTimeout(() =>{
      onClose();
    }, 2000);
  }, []);

  return(
    <div className="flex_centrado">
        <h2><i class="fa fa-exclamation-triangle error_color" aria-hidden="true"></i>Ha ocurrido un error!</h2>
      </div>
  );
}

export default function FlujoIncode({ onSuccess, onError }){
  const [paso, setPaso] = useState(1);
  const [session, setSession] = useState();

  useEffect(() => {

    incode
      .createSession("ALL", null, {
        configurationId: incodeFlowId,
      })
      .then(async (session) => {
        await incode.warmup();
        setSession(session);
      });
  }, []);

  function siguiente(){
    setPaso(paso +1);
  }

  if (!session){
    return (
      <div className="flex_centrado">
        <img className="loading_lector_INE" src={loadingicon} alt="" />
      </div>
    );
  }
  
  return(
    <Pasos pasoActual={paso}>
      <Error onClose={onError}/>
      <FrontId session={session} onSuccess={siguiente} onError={() => setPaso(0)}/>
      <BackId session={session} onSuccess={siguiente} onError={() => setPaso(0)}/>
      <ProcessId session={session} onSuccess={siguiente}/>
      <Selfie session={session} onSuccess={onSuccess} onError={() => setPaso(0)}/>
    </Pasos>
  );
}
