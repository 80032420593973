import "../../App.css";
import {
  TarjetaBancosGuardados,
  TarjetaFijosGuardados,
  TarjetaFijosSeleccionadosResponsivo,
} from "../../components/Cards";
import { useState, useEffect } from "react";
import { get } from "../../utils/http";
import { useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { ModalLoadingGeneral } from "../../components/Modals";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { obtEmpresaUrl } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Pantalla donde se muestran las ofertas(Bancos) seleccionadas --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function BancosSeleccionados() {
  const navigate = useNavigate();
  const actionSetDataPublic = useSetDataPublic();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const [tituloTablaBancos, setTituloTablaBancos] = useState(false);
  const [tarjetasNoSeleccionadas, setTarjetasNoSeleccionadas] = useState(false);

  let bancosTarjetas;
  const showBancos = async () => {
    let totalSeleccionados = 0;
    let idCuestionarioBancos = localStorage.getItem("verBancos");
    let idResPaso1 = localStorage.getItem("idResPaso1Cuestionario");
    let datosCotizacion = await get("obtDatosCotizacionPorId", idResPaso1);

    if (idCuestionarioBancos !== undefined) {
      let dataGet = await get("userBancos", idCuestionarioBancos); //
      //console.log("Bancos seleccionados: ", dataGet);
      let datosCotizacion = await get("obtDatosCotizacionPorId", idResPaso1); //
      //console.log("Datos cotizacion: ", datosCotizacion);

      if (dataGet.statucode === 200) {
        let infoBancos = []; // --- Total de los bancos ---
        let infoBancosSeleccionados = []; // --- BancosSeleccionados ---

        for (let num = 0; num < dataGet.bancos.length; num++) {
          let datosBanco = await get(
            "obtDescripcionBanco",
            dataGet.bancos[num].productoNombre
          );
          let descripcionBanco;
          if (datosBanco.statuscode === 200) {
            descripcionBanco = datosBanco.banco.descripcionBanco;
          }

          if (dataGet.bancos[num].bancoSeleccionado === false) {
            totalSeleccionados += 1;
            actionSetDataPublic({
              cuestionarioId: dataGet.bancos[num].idCuestionario,
            });
            let jsonBanco = dataGet.bancos[num];
            jsonBanco.descripcion = descripcionBanco;
            infoBancos.push(jsonBanco);
          } else if (dataGet.bancos[num].bancoSeleccionado === true) {
            let jsonBanco = dataGet.bancos[num];
            jsonBanco.descripcion = descripcionBanco;
            infoBancosSeleccionados.push(jsonBanco);
          }
        }

        if (totalSeleccionados === dataGet.bancos.length) {
          navigate("/Bancos");
        }

        // <---------- Renderizar tarjetas con ofertas seleccionadas ---------->
        bancosTarjetas = infoBancosSeleccionados.map((banco) => (
          <TarjetaBancosGuardados
            idTarjeta={banco.idResPrecalifica}
            banco={banco.productoNombre}
            creditoOfertado={banco.prestamoMax}
            Plazo={localStorage.getItem("plazoParaBancosSeleccionados")}
            Mensualidad={banco.pagoMensual}
            Tasa={banco.tasa}
            tipoPago={banco.pago}
            bancoSeleccionado={banco.bancoSeleccionado}
            descripcion={banco.descripcion}
            idResPrecalifica={banco.idResPrecalifica}
            idCuestionario={banco.idCuestionario}
            PorcentajeTasa={
              banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
            }
            montoSolicitado={datosCotizacion.cotizacion.montoSolicitado}
          />
        ));
        const root = ReactDOM.createRoot(
          document.getElementById("mostrarMostrarBancos")
        );
        root.render(bancosTarjetas);

        let arrayOrdenado = infoBancos.sort(
          (x, y) => x.pagoMensual - y.pagoMensual
        );

        // <---------- Renderizar tarjetas con ofertas no seleccionadas ---------->
        let bancosTarjetasNoSeleccionadas = arrayOrdenado
          .slice(0, arrayOrdenado.length / 2)
          .map((banco) => (
            <TarjetaBancosGuardados
              idTarjeta={banco.idResPrecalifica}
              banco={banco.productoNombre}
              creditoOfertado={banco.prestamoMax}
              Plazo={localStorage.getItem("plazoParaBancosSeleccionados")}
              Mensualidad={banco.pagoMensual}
              Tasa={banco.tasa}
              tipoPago={banco.pago}
              bancoSeleccionado={banco.bancoSeleccionado}
              descripcion={banco.descripcion}
              PorcentajeTasa={
                banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
              }
              clicDeshabilitado={true}
            />
          ));
        if (bancosTarjetasNoSeleccionadas.length !== 0) {
          setTarjetasNoSeleccionadas(true);
        }

        const root2 = ReactDOM.createRoot(
          document.getElementById("mostrarBancosNoSeleccionadosTarjetas")
        );
        root2.render(bancosTarjetasNoSeleccionadas);
        // <---------- Renderizar tabla con ofertas (No responsivo) ---------->
        let tablaDeAbajo = arrayOrdenado
          .slice(arrayOrdenado.length / 2, arrayOrdenado.length)
          .map((banco) => (
            <TarjetaFijosGuardados
              idTarjeta={banco.idResPrecalifica}
              banco={banco.productoNombre}
              creditoOfertado={banco.prestamoMax}
              valorPropiedad = {datosCotizacion.cotizacion.valorPropiedad}
              enganche = {datosCotizacion.cotizacion.enganche}
              montoSolicitado={datosCotizacion.cotizacion.montoSolicitado}
              Plazo={localStorage.getItem("plazoParaBancosSeleccionados")}
              Mensualidad={banco.pagoMensual}
              Tasa={banco.tasa}
              tipoPago={banco.pago}
              descripcion={banco.descripcion}
              PorcentajeTasa={
                banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
              }
            />
          ));

        const root3 = ReactDOM.createRoot(
          document.getElementById("mostrarBancosNoSeleccionadosTabla")
        );
        root3.render(tablaDeAbajo);

        // <---------- Renderizar tabla con ofertas (Responsivo) ---------->
        let tablaDeAbajoResponsivo = arrayOrdenado
          .slice(arrayOrdenado.length / 2, arrayOrdenado.length)
          .map((banco) => (
            <TarjetaFijosSeleccionadosResponsivo
              idTarjeta={banco.idResPrecalifica}
              banco={banco.productoNombre}
              creditoOfertado={banco.prestamoMax}
              valorPropiedad = {datosCotizacion.cotizacion.valorPropiedad}
              enganche = {datosCotizacion.cotizacion.enganche}
              montoSolicitado={datosCotizacion.cotizacion.montoSolicitado}
              Plazo={localStorage.getItem("plazoParaBancosSeleccionados")}
              Mensualidad={banco.pagoMensual}
              Tasa={banco.tasa}
              tipoPago={banco.pago}
              descripcion={banco.descripcion}
              PorcentajeTasa={
                banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
              }
            />
          ));

        if (tablaDeAbajo.length !== 0) {
          setTituloTablaBancos(true);
        }

        const root4 = ReactDOM.createRoot(
          document.getElementById("mostrarBancosNoSeleccionadosTablaResponsivo")
        );
        root4.render(tablaDeAbajoResponsivo);

        setIsOpenModalLoading(false);
      } else if (dataGet.statucode === 400) {
        Swal.fire({
          icon: "error",
          title: "Sin ofertas disponibles",
          text: "Inténtelo de nuevo.",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            showBancos();
          }
        });

        setIsOpenModalLoading(false);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error al obtener ofertas",
        text: "Inténtelo de nuevo.",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.isConfirmed) {
          showBancos();
        }
      });

      setIsOpenModalLoading(false);
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    showBancos();
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1 className="titt">Estas son las ofertas aplicadas</h1>
        <br />
        <br />
        {/* <---------- Ofertas seleccionadas ----------> */}
        <div
          className="cont_flex con_flex_wrap"
          id="mostrarMostrarBancos"
        ></div>

        {/* <---------- Ofertas nos seleccionadas ----------> */}
        {tarjetasNoSeleccionadas === true && (
          <h2 className="titt">Ofertas no seleccionadas </h2>
        )}
        <div
          className="cont_flex con_flex_wrap"
          id="mostrarBancosNoSeleccionadosTarjetas"
        ></div>

        {/* <---------- Titulo de tabla con ofertas (No responsivo) ----------> */}
        {tituloTablaBancos === true && (
          <div className="tablaFijosCeldasTitulo" id="tituloTablaBancosFijos">
            <div class="etiquetaTablaBancos">
              <b>Nombre</b>
            </div>
            <div class="etiquetaTablaBancos">
              <b>Oferta</b>
            </div>
            <div class="etiquetaTablaBancos">
              <b>Plazo</b>
            </div>
            <div class="etiquetaTablaBancos">
              <b>Mensualidad</b>
            </div>
            <div class="etiquetaTablaBancos">
              <b>Tipo Pago</b>
            </div>
            <div class="etiquetaTablaBancos">
              <b>Tasa</b>
            </div>
            <div class="botonTablaBancos">
              <b>Información adicional</b>
            </div>
          </div>
        )}

        {/* <---------- Tabla con ofertas (No responsivo) ----------> */}
        <div
          className="cont_flex con_flex_wrap"
          id="mostrarBancosNoSeleccionadosTabla"
        ></div>
        {/* <---------- Tabla con ofertas (Responsivo) ----------> */}
        <div id="mostrarBancosNoSeleccionadosTablaResponsivo"></div>

        <div className="Resum cont-Cotizaciones" id="cont-Seleccion"></div>
        <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
      </div>
    </>
  );
}
