import '../../../App.css'
import HeaderSocio from '../../../components/HeaderSocio'
import NavSocio from '../../../components/NavSocio'
import FooterHome from '../../../components/FooterHome'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------------------- Home con menú lateral ---------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function HomeSocios({children,active}){
    return(
        <>
            <HeaderSocio/>
            <div className="cont_flex">
                <NavSocio active={active}/>
                {children}
            </div> 
            <FooterHome/>
        </>
    )
}