function obtenerAnioCompleto(ultimosDigitos){
  ultimosDigitos = parseInt(ultimosDigitos, 10)
  const anioActual = new Date().getFullYear()
  const primDigitosAnioActual = Math.floor(anioActual/100)

  var siglo;
  // �ltimos d�gitos de a�o actual 
  if ((anioActual % 100) > ultimosDigitos) {
      siglo = primDigitosAnioActual * 100;
  } else {
      siglo = (primDigitosAnioActual - 1) * 100;
  }

  var anioCompleto = siglo + ultimosDigitos;
  return ""+anioCompleto
}

export function fechaNacimientoDeRFC(rfc){    
  const fecha = rfc.length == 12 ? rfc.substring(3, 9): rfc.substring(4, 10);
  
  var anio = fecha.substring(0, 2);
  const mes = fecha.substring(2, 4);
  const dia = fecha.substring(4, 6);

  anio = obtenerAnioCompleto(anio);

  return `${dia}/${mes}/${anio}`;
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}