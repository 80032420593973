import "../App.css";
import "../sass/style.css";
import { get } from "../utils/http";
import logoMorado from "../images/logo_morado.svg";
import isotipo_negro from "../images/isotipo-negro.svg";
import logo_negro from "../images/logotipo-negro.png";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { obtenerClientes } from "./Funciones";
import { Link } from "react-router-dom";
import ObtUsuarioRemaxComponent from "./Funciones";
import { MenuLateralResponsivoSocios } from "../components/Modals";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario sí esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function HeadHome() {
  const [imagenSrc, setImagenSrc] = useState(isotipo_negro);
  const [isotipoClass, setIsotipoClass] = useState("isotipo");

  const [isOpenModalMenuLateral, setIsOpenModalMenuLateral] = useState(false);
  const cookies = new Cookies();
  const [dataClientes, setDataClientes] = useState("");
  const idSocios = cookies.get("idSocio");

  const logOut = () => {
    localStorage.removeItem("idResPaso1Cuestionario");
    localStorage.removeItem("BancoLS1");
    localStorage.removeItem("BancoLS2");
    localStorage.removeItem("idResPaso1SeleccionarBancos");
    localStorage.removeItem("verBancos");

    localStorage.removeItem("monto");
    localStorage.removeItem("plazoVal");
    localStorage.removeItem("montoPrestar");
    localStorage.removeItem("enganche");
    localStorage.removeItem("tasa");
    localStorage.removeItem("mensualidad");

    localStorage.removeItem("carruselInicioSesion");

    cookies.remove("sessionSocio");
    cookies.remove("nombreSocio");
    cookies.remove("correoSocio");
    cookies.remove("idSocios");
    cookies.remove("idSocio");
    window.location.href = "./inicioSocios";
  };

  useEffect(() => {
    obtenerClientes(idSocios, setDataClientes);
  }, [idSocios]);

  return (
    <>
      <ObtUsuarioRemaxComponent
        idSocios={idSocios}
        setImagenSrc={setImagenSrc}
        setIsotipoClass={setIsotipoClass}
      />
      <header className="header">
        <div>
          <Link to="/panelSocios" className={isotipoClass}>
            <img src={imagenSrc} alt="logo" />
          </Link>
          <Link to="/panelSocios" className={"ocultar_logoSaturn"}>
            {" "}
            <img src={logo_negro} alt="logo" />
          </Link>
        </div>
        <ul className="menu">
          {cookies.get("idSocio") ? (
            <li className="logoSesionIniciada">
              <i className="fa  fa-user iconoUsuarioLoggueado"></i>
              <select
                id="select_login"
                onChange={() => {
                  logOut();
                }}
              >
                <option> {cookies.get("nombreSocio")}</option>
                <option> Cerrar Sesion</option>
              </select>
            </li>
          ) : null}
        </ul>
      </header>
      <div className="header_responsive">
        <div>
          {/* <Link to="/home" className={isotipoClass}>
            <img src={logoMorado} alt="logo" />{" "}
          </Link> */}
        </div>
        <button
          type="button"
          aria-label="Toggle Navigation"
          className="lines-button x"
          onClick={() => setIsOpenModalMenuLateral(true)}
        >
          <span className="lines"></span>
        </button>
        <MenuLateralResponsivoSocios
          open={isOpenModalMenuLateral}
          close={() => setIsOpenModalMenuLateral(false)}
        ></MenuLateralResponsivoSocios>

        <ul className="menu_responsive">
          <li>
            <select id="select_login">
              <option>Hola, Nombre Usuario</option>
              <option>Datos</option>
              <option>Otros</option>
              <option
                onClick={() => {
                  logOut();
                }}
              >
                Cerrar Sesion
              </option>
            </select>
          </li>

          <li>
            <a href="resumen.html" className="active">
              <i className="fa fa-grid-2"></i> Clientes
            </a>
          </li>
          <li>
            <a href="datos.html">
              <i className="fa  fa-user"></i> Invitaciones
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
