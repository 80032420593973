import "../../../App.css";
import "../../../sass/style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { obtenerClientes } from "../../../components/Funciones";
import ObtUsuarioRemaxComponent from "../../../components/Funciones";
import { Tabla, TablaBancosNoSelect } from "../../../components/Tablas";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { get, put } from "../../../utils/http.js";
import { ModalInicioSocios } from "../../../components/Modals.jsx";

export default function PanelSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const idSocios = cookies.get("idSocio");
  const [dataClientes, setDataClientes] = useState("");
  const [dataClientesNoBanco, setDataClientesNoBanco] = useState("");
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();

        setEmpresaLogo(empresa.configuracion.img);

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    obtenerClientes(idSocios, setDataClientes, setDataClientesNoBanco);
  }, [idSocios]);
  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {
        habilitarCarrusel && (
          <ModalInicioSocios logo={empresaLogo} onClose={cierraCarrusel}/>
        )
      }

      <ObtUsuarioRemaxComponent idSocios={idSocios} />
      <div className=" cont_Info">
        <h1 className="titt">Mis clientes</h1>
        <p className="descripcionClientes">
          Aquí puedes visualizar el progreso de tus clientes.
        </p>

        <h2>Clientes</h2>
        <div>
          <Tabla arrData={dataClientes} ></Tabla>
        </div>


        <h2>Clientes con bancos no seleccionados</h2>
        <div>
          <TablaBancosNoSelect arrData={ dataClientesNoBanco}></TablaBancosNoSelect>
        </div>

      </div>
    </>
  );
}