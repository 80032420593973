import React, { useEffect, useState } from "react";
import { get } from "../utils/http";
import Cookies from "universal-cookie";
import isotipo_negro from "../images/isotipo-negro.svg";

/** ----------------------------------------------------------- **/
/** ------Lógica para cambiar desde BD----------- **/
/** ----------------------------------------------------------- **/

const cookies = new Cookies();

const ObtUsuarioRemaxComponent = ({
  idSocios,
  setImagenSrc,
  setMostrarBotonHomeSocios,
  tituloBienvenida,
  setTituloBienvenida,
}) => {
  const [nuevoTituloBienvenida, setNuevoTituloBienvenida] =
    useState(tituloBienvenida);
  const [isotipoClass, setIsotipoClass] = useState("isotipo");
  const aplicarEstilos = (altColors, configuracion, confiAdicional) => {
    const root = document.documentElement;

    root.style.setProperty(
      "--ColorBoton",
      altColors ? configuracion.colorPrimario : ""
    );
    root.style.setProperty(
      "--ColorTexto",
      altColors ? configuracion.colorSecundario : ""
    );
    root.style.setProperty(
      "--FuentePrincipal",
      altColors ? confiAdicional.fuentePrimaria : ""
    );
    root.style.setProperty(
      "--FuenteSecundaria",
      altColors ? confiAdicional.fuenteSecundaria : ""
    );
  };

  const mostrarOcultarElementos = (elemento, condicion) => {
    const elementoDOM = document.querySelector(elemento);

    if (elementoDOM) {
      elementoDOM.style.display = condicion ? "block" : "none";
    } else {
      // Si el elemento no se encuentra, puedes emitir un mensaje de advertencia
      console.warn(`Elemento ${elemento} NO encontrado`);
    }
  };

  const condicionesParaCambiarDatos = (configuracion) => {
    const copyrightElement = document.querySelector(".copyright");

    if (
      configuracion &&
      configuracion.confiAdicional &&
      configuracion.confiAdicional.avisoPrivacidad === 1
    ) {
      copyrightElement.style.display = "block";
    } else {
      copyrightElement.style.display = "none";
    }
  };

  useEffect(() => {
    const obtenerConfiguraciones = async () => {
      try {
        let remax = cookies.get("remax");
        if (remax) {
          let configuraciones = await get("obtConfiguraciones", idSocios);
          const altColors = true;

          aplicarEstilos(
            altColors,
            configuraciones.configuracion,
            configuraciones.confiAdicional
          );
          mostrarOcultarElementos(
            ".titt",
            configuraciones.confiAdicional.tituloClientes === 1
          );
          mostrarOcultarElementos(
            ".descripcionClientes",
            configuraciones.confiAdicional.descripcionClientes === 1
          );
          mostrarOcultarElementos(
            ".ocultar_menu",
            configuraciones.confiAdicional.logoEmpresa === 1
          );
          mostrarOcultarElementos(
            ".menu",
            configuraciones.confiAdicional.login === 1
          );
          mostrarOcultarElementos(
            ".logo_negro",
            configuraciones.confiAdicional.logoEmpresa_2 === 1
          );

          // Cambiar dinámicamente la imagen según la condición
          const logoEmpresa = localStorage.getItem("logoEmpresa");
          const nuevaImagenSrc =
            configuraciones.confiAdicional.logoEmpresa === 1
              ? logoEmpresa
              : isotipo_negro;

          // Modificar la clase según las condiciones
          setIsotipoClass(
            configuraciones.confiAdicional.logoEmpresa === 1
              ? "isotipo_remax"
              : "isotipo"
          );

          const mostrarBotonHomeSocios =
            remax && configuraciones.confiAdicional.acceso_homeSocios === 1;
          setMostrarBotonHomeSocios(mostrarBotonHomeSocios);

          condicionesParaCambiarDatos(configuraciones.confiAdicional);
        }
      } catch (error) {
        console.error("Error al obtener configuraciones:", error);
      }
    };

    obtenerConfiguraciones();
  }, [idSocios, setImagenSrc, setMostrarBotonHomeSocios, tituloBienvenida]);

  return null;
};

/** ----------------------------------------------------------- **/
/** ------Lógica para realizar la consulta para clientes ----------- **/
/** ----------------------------------------------------------- **/

export const obtenerClientes = async (
  idSocios,
  setDataClientes,
  setDataClientesNoBanco
) => {
  try {
    //const data = await get("getClientes", idSocios);
    const data = await get("getClientes3", idSocios);
    if (data && data.statuscode === 200) {
      setDataClientes(data.clientes);
      //let bancosOrdenados = await ordenarBancos(data.bancosNoSelect);
      setDataClientesNoBanco(data.bancosNoSelect.bancosNoSelect);
      // (data.bancosNoSelect);
      //setDataClientesNoBanco(bancosOrdenados);
      //xxxx(data.bancosNoSelect);
    } else {
      //console.error("Error en la consulta: ", data && data.message);
    }
  } catch (error) {
    console.error("Error al obtener clientes: ", error);
  }
};

export default ObtUsuarioRemaxComponent;

export const mostrarOcultarElementos = (elemento, condicion) => {
  const elementoDOM = document.querySelector(elemento);

  if (elementoDOM) {
    elementoDOM.style.display = condicion ? "block" : "none";
  } else {
    // (`Elemento ${elemento} NO encontrado`);
  }
};

export const obtenerCotizaciones = async (idSocios, setDataClientes) => {
  try {
    const data = await get("obtComisiones", idSocios);
    if (data && data.statuscode === 200) {
      setDataClientes(data.data);
    } else {
      //console.error("Error en la consulta: ", data && data.message);
    }
  } catch (error) {
    console.error("Error al obtener clientes: ", error);
  }
};