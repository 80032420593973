import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, post } from "../../utils/http";
import { InfoCotizacion } from "../../components/Cards";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { TarjetaSubirDocumentacion } from "../../components/Cards";
import { TarjetaCalculoDias } from "../../components/Cards";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import { TarjetaFormalizacion } from "../../components/Cards";
import { NotasSolicitud } from "../../components/Msjs";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
import { PasosAseguir } from "../../components/Cards";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Felicidades() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");
      // let nombrePantalla  = (credito > 0)  ? "8.Cierre y formalización" : "7.Felicidades";

      // let  pantallaActualMenu = <PasosAseguir
      //     nombrePantalla ={nombrePantalla}
      //     urlActual = {urlActual}
      //     credito = {credito}
      // />
      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = credito > 0 ? "8." : "7.";
      let descripcionPaso =
        credito > 0 ? "Cierre y formalización" : "Felicidades";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    console.log(pantallasFlujoBanco[idPantallaUrlActual].paso);
    console.log(pantallasFlujoBanco[idPantallaActual].paso);
    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtPantallaActual();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>
        <div className="cont_flex">
          <div className="col6 width100">
            <h1>Felicidades</h1>
            <p>
              Ya cuentas con tu nueva vivienda. Estamos muy felices de completar
              este proceso de la mano contigo.
              <br /> Sigues contando con nosotros para cualquier consulta o
              tema.
            </p>

            <a href="/home" className="btn btn_morado">
              Regresar a mi tablero
            </a>
          </div>
          <div className="col6 cont_img-index width100">
            <img
              src="/static/media/login.3679aa2ae6d4a098e832.jpg"
              alt="Login"
            />
          </div>
        </div>
      </div>
    </>
  );
}
