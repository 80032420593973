import "../App.css";
import "../sass/style.css";
import { Link } from "react-router-dom";
import isotipo_morado from "../images/isotipo_morado.svg";
import { obtEmpresaUrl } from "../utils/global_functions";
import { useEffect, useState } from "react";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Footer cuando el usuario si esta logueado ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function FooterHome() {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    async function obtEmpresaConfig(){
      const config = await obtEmpresaUrl();
      setLogo(config.confiAdicional?.logoBlanco == undefined ? "" : config.confiAdicional?.logoBlanco);
    }

    obtEmpresaConfig();
  }, []);
  return (
    <>
      <footer className="cont_responsive">
        <div className="copyright">
          <a href="#/" className="isotipo_blanco" target="_blank">
            <img className="isotipo_blanco" src={logo} alt="saturn 5" />
          </a>
          <li>
            {" "}
            <Link to="/AvisoPrivacidadLogueado" className="link">
              Aviso de privacidad
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/TerminosCondicionesLogueado" className="link">
              Términos y condiciones
            </Link>
          </li>
          <p className="copy">© 2024. Saturn5 Technologies SAPI de CV</p>
        </div>
      </footer>
    </>
  );
}
