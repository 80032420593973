import { Routes, Route } from "react-router-dom";
import { Raiz, Index } from "./pages/Index";
import { RaizSocios, InicioSocio } from "./pages/InicioSocios"; //JPB 07-09-2023 Nuevo login para Socios
import CrearCuentaSocios from "./pages/noAuth/CrearCuentaSinSimulacionSocios";

import { PreInformacion, InfoDocumentacionInicial, InfoLaborar, InfoPersonal, InfoMedica, InfoInmueble, DocumentosEnviados } from "./pages/auth/Informacion";
import { Home } from './pages/auth/Home'
import { HomeSocios } from './pages/auth/socios/screenSocios'
import Resumen from './pages/auth/Resumen'
import { MisDatos, Simulador } from "./pages/auth/MisDatos"
import Cotizaciones from "./pages/auth/Cotizaciones"

//JPB 26-09-2023
import CotizacionesAplicadas from "./pages/auth/CotizacionesAplicadas"

import Bancos from "./pages/auth/Bancos"
import BancosSeleccionados from "./pages/auth/BancosSeleccionados"
import Paso1Logeado from "./pages/auth/Paso1Logueado"
import RespuestaBuro from "./pages/auth/RespuestaBuro"
import DescargarSolicitud from "./pages/auth/DescargarSolicitud"

import CrearCuenta from "./pages/noAuth/CrearCuenta"
import CrearCuentaSinSimulacion from "./pages/noAuth/CrearCuentaSinSimulacion"
import Paso1 from "./pages/noAuth/Paso1"
import ValidarPass from "./pages/noAuth/ValidarPass"
import RecuperaPass from "./pages/noAuth/RecuperaPass"

import CalculadoraWeb from "./pages/generales/CalculadoraWeb"
import CalculadoraWebLiquidez from "./pages/generales/CalculadoraWebLiquidez";
import CalculadoraWebFirmauto from "./pages/generales/CalculadoraWebFirmauto"
import AvisoPrivacidad from "./pages/generales/AvisoDePrivacidad"
import TerminosCondiciones from "./pages/generales/TerminosCondiciones"

import { InformacionGeneral } from "./pages/auth/InformacionGeneral"
import { Auth, NoAuth, AuthSocio, NoAuthSocios } from './components/Auth'
import Cookies from 'universal-cookie'
import { PublicProvider } from './providers/dataPublic/PublicProvider'
import SolicitudAceptada from "./pages/auth/SolicitudAceptada";
import DocumentosVivienda from "./pages/auth/DocumentosVivienda";
import DocumentosViviendaDocs from "./pages/auth/DocumentacionViviendaDocs"
import InfoVendedorVivienda from "./pages/auth/InfoVendedorVivienda"
import PagoEvaluo from "./pages/auth/PagoEvaluo"
import Avaluo from "./pages/auth/Avaluo"
import NotariaAsignada from "./pages/auth/NotariaAsignada"
import InsExterna from "./pages/auth/InsExterna"
import FormalizacionFirma from "./pages/auth/FormalizacionFirma"
import Felicidades from "./pages/auth/Felicidades"

import RecuperarPassSocio from "./pages/noAuth/RecuperarPassSocio"
import PanelSocios from "./pages/auth/socios/PanelSocios";
import Home_Socios from "./pages/auth/socios/Home_Socios";
import InvitacionesSocios from "./pages/auth/socios/invitacionesSocios";
import NuevoCliente from "./pages/auth/socios/frmNuevoCliente";
import MisComisiones from "./pages/auth/socios/MisComisiones";


import InfoLaboral from "./pages/auth/frmLaboral";
import InfoFrmMedica from "./pages/auth/frmMedica";
import InfoPersonalForm from "./pages/auth/frmPersonal";
import InfoGeneral from "./pages/auth/infoGeneral";



//Informacion general del coAcreditado
import InfoGeneralCoAcreditado from "./pages/auth/infoGeneralCoAcreditado";
import { InfoDocumentacionInicialCoAcreditado } from "./pages/auth/informacionCoAcreditado"
import { InfoPersonalCoAcreditado } from "./pages/auth/frmPersonalCoAcreditado"
import InfoLaboralCoAcreditado from "./pages/auth/frmLaboralCoAcreditado";
import InfoFrmMedicaCoAcreditado from "./pages/auth/frmMedicaCoAcreditado";
import InfoFrmDatosExtra from "./pages/auth/frmDatosExtra";

import { LogoProvider } from './providers/LogoProvider'

//import MyForm from "./pages/auth/ejemplo"

import Paso1Dinamico from "./pages/noAuth/Paso1Dinamico";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Declaración de rutas para mostrar las diferentes pantallas  --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
function App() {
  const cookie = new Cookies()
  return (

    <PublicProvider>
      <Routes>
        <Route element={<NoAuth session={cookie.get('session')} />}>
          <Route path="/" element={<Raiz><Index /></Raiz>} />


          <Route path="/crearcuenta" element={<Raiz><CrearCuenta /></Raiz>} />
          <Route path="/crearcuentaSinSimulacion" element={<Raiz><CrearCuentaSinSimulacion /></Raiz>} />


          <Route path="/recuperapass" element={<Raiz><RecuperaPass /></Raiz>} />
          <Route path="/validarPass" element={<Raiz><ValidarPass /></Raiz>} />



          <Route path='/calculadora' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className=" cont_Info">
                  <CalculadoraWeb />
                </div>
              </div>
            </Raiz>}
          />

          <Route path='/calculadoraLiquidez' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className=" cont_Info">
                  <CalculadoraWebLiquidez />
                </div>
              </div>
            </Raiz>}
          />

          <Route path='/calculadoraFirmauto' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className=" cont_Info">
                  <CalculadoraWebFirmauto />
                </div>
              </div>
            </Raiz>}
          />

          <Route path="/pre_registro" element={<Raiz><Paso1 /></Raiz>} />
          <Route path="/preRegistroDinamico" element={<Raiz><Paso1Dinamico/></Raiz>} />

        </Route>
        {/* <---------- Rutas protegidas (Usuario logueado) ----------> */}
        <Route element={<Auth session={cookie.get('session')} />}>
          <Route path="/home" element={<Home active='01'><Resumen /></Home>} />
          <Route path="/misDatos" element={<Home active='02'><MisDatos /></Home>} />
          <Route path="/simulacion" element={<Home active='02'><Simulador /></Home>} />
          <Route path="/cotizaciones" element={<Home active='03'><Cotizaciones /></Home>} />

          {/*JPB 26-09-2023*/}
          <Route path="/cotizacionesAplicadas" element={<Home active='04'><CotizacionesAplicadas /></Home>} />

          <Route path="/informacion" element={<Home active='06'><PreInformacion /></Home>} />
          <Route path="/informacion/inf-DocumentacionInicial" element={<Home active=''><InfoDocumentacionInicial /></Home>} />
          <Route path="/informacion/inf-laboral" element={<Home active=''><InfoLaborar /></Home>} />
          <Route path="/informacion/inf-personal" element={<Home active=''><InfoPersonal /></Home>} />
          <Route path="/informacion/inf-medica" element={<Home active='04'><InfoMedica /></Home>} />
          <Route path="/informacion/inf-inmueble" element={<Home active='04'><InfoInmueble /></Home>} />
          <Route path="/informacionEnviada" element={<Home active='04'><DocumentosEnviados /></Home>} />

          <Route path="/informacionGeneral" element={<Home active='05'><InformacionGeneral /></Home>} />

          <Route path="/observarRegistro" element={<Home active='03'><Paso1Logeado /></Home>} />
          <Route path="/AvisoPrivacidadLogueado" element={<Home active=''><AvisoPrivacidad /></Home>} />
          <Route path="/TerminosCondicionesLogueado" element={<Home active=''><TerminosCondiciones /></Home>} />
          <Route path="/Bancos" element={<Home active='03'><Bancos /></Home>} />
          <Route path="/BancosSeleccionados" element={<Home active='03'><BancosSeleccionados /></Home>} />
          <Route path="/respuestaBuro" element={<Home active='03'><RespuestaBuro /></Home>} />
          <Route path="/descargarSolicitud" element={<Home active='07'><DescargarSolicitud /></Home>} />
          <Route path="/solicitudAceptada" element={<Home active='03'><SolicitudAceptada /></Home>} />
          <Route path="/documentosVivienda" element={<Home active='03'><DocumentosVivienda /></Home>} />
          <Route path="/documentacionViviendaDocs" element={<Home active='03'><DocumentosViviendaDocs /></Home>} />
          <Route path="/infoVendedorVivienda" element={<Home active='03'><InfoVendedorVivienda /></Home>} />
          <Route path="/pagoEvaluo" element={<Home active='03'><PagoEvaluo /></Home>} />
          <Route path="/avaluo" element={<Home active='03'><Avaluo /></Home>} />
          <Route path="/notariaAsignada" element={<Home active='03'><NotariaAsignada /></Home>} />
          <Route path="/institucionExterna" element={<Home active='03'><InsExterna /></Home>} />
          <Route path="/formalizacionFirma" element={<Home active='03'><FormalizacionFirma /></Home>} />
          <Route path="/felicidades" element={<Home active='03'><Felicidades /></Home>} />


          <Route path="/frmInfoLaboral" element={<Home active='03'><InfoLaboral /></Home>} />
          <Route path="/frmInfoMedica" element={<Home active='03'><InfoFrmMedica /></Home>} />
          <Route path="/frmInfoPersonal" element={<Home active='03'><InfoPersonalForm /></Home>} />
          <Route path="/informacion-general" element={<Home active='05'><InfoGeneral /></Home>} />
          {/*---------- Informacion del CoAcreditado ----------*/}

          {/* Inicio del CoAcreditado*/}
          <Route path="/InfocoAcreditado" element={<Home active='06'><InfoGeneralCoAcreditado /></Home>} />


          {/* formularios de Co acreditadto */}
          <Route path="/informacion/inf-DocumentacionInicial-coAcreditado" element={<Home active=''><InfoDocumentacionInicialCoAcreditado /></Home>} />
          <Route path="/frmPersonal-coAcreditado" element={<Home active=''><InfoPersonalCoAcreditado /></Home>} />
          <Route path="/frmInfoLaboral-coAcreditado" element={<Home active=''><InfoLaboralCoAcreditado /></Home>} />
          <Route path="/frmInfoMedica-coAcreditado" element={<Home active=''><InfoFrmMedicaCoAcreditado /></Home>} />
          <Route path="/frmDatosExtra" element={<Home active=''><InfoFrmDatosExtra /></Home>} />

        </Route>

        {/* <---------- Rutas protegidas socios (socio logueado) ----------> */}
        <Route element={<AuthSocio session={cookie.get('sessionSocio')} />}>
          <Route path="/panelSocios" element={<HomeSocios active='01'><PanelSocios /></HomeSocios>} />
          <Route path="/invitacionesSocios" element={<HomeSocios active='02'><InvitacionesSocios /></HomeSocios>} />
          <Route path="/frmNuevoCliente" element={<HomeSocios active='02'><NuevoCliente /></HomeSocios>} />
          <Route path="/Home_Socios" element={<HomeSocios active='03'><Home_Socios /></HomeSocios>} />
          <Route path="/misComisiones" element={<HomeSocios active='04'><MisComisiones /></HomeSocios>} />
        </Route>

        {/* <---------- Rutas no protegidas socios  ----------> */}
        <Route element={<NoAuthSocios session={cookie.get('sessionSocio')} />}>

          <Route path="/InicioSocios" element={<RaizSocios><InicioSocio /></RaizSocios>} />
          <Route path="/CrearCuentaSocios" element={<RaizSocios verificar="true" ><CrearCuentaSocios /></RaizSocios>} />
          <Route path="/recuperapassSocios" element={<RaizSocios><RecuperarPassSocio /></RaizSocios>} />

        </Route>

        <Route path="/avisoDePrivacidad" element={<AvisoPrivacidad />} />
        <Route path="/terminosCondiciones" element={<TerminosCondiciones />} />

        {/* <---------- Pantalla mostrada cuando la URL no se encuentra ----------> */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </PublicProvider>

  );
}

export default App;
