import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get } from "../../utils/http";
import { InfoCotizacion } from "../../components/Cards";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { ConCotizacionP13 } from "../../components/Msjs";
import { ModalLoadingGeneral } from "../../components/Modals";
import { obtEmpresaUrl } from "../../utils/global_functions";
//JPB
import ResumenCotizaciones from "./ResumenCotizaciones";
import ResumenCardsBancos from "./ResumenCardsBancos";
import { SinCotizacion } from "../../components/Msjs"; //mensaje sin solicitudes (bancos)
import { SinSolicitudes } from "../../components/Msjs"; //mensaje sin solicitudes (bancos)
import { SinCotizacionYSolicitud } from "../../components/Msjs"; //mensaje sin solicitudes (bancos)
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial con las tarjetas de todas las cotizaciones --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Resumen() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();

  //JPB
  const [cuentaConCotizaciones, setCuentaConCotizaciones] = useState(true);
  const [cuentaConSolicitudes, setCuentaConSolicitudes] = useState(true);
  const [cotizacionConvertidaEnSolicitud, setCotizacionConvertidaEnSolicitud] =
    useState(true);
  let idSolicitudes = [];
  let solicitud = false;

  localStorage.removeItem("idResPaso1Cuestionario");
  localStorage.removeItem("BancoLS1");
  localStorage.removeItem("BancoLS2");
  localStorage.removeItem("idResPaso1SeleccionarBancos");
  localStorage.removeItem("verBancos");
  localStorage.removeItem("plazoParaBancosSeleccionados");

  localStorage.removeItem("monto");
  localStorage.removeItem("plazoVal");
  localStorage.removeItem("montoPrestar");
  localStorage.removeItem("enganche");
  localStorage.removeItem("tasa");
  localStorage.removeItem("mensualidad");

  localStorage.removeItem("nombreCalculadora");
  localStorage.removeItem("apellidoPCalculadora");
  localStorage.removeItem("apellidoMCalculadora");
  localStorage.removeItem("correoCalculadora");
  localStorage.removeItem("idResPaso1Buro");
  localStorage.removeItem("sinRespuestaBuro");

  localStorage.removeItem("statuscodepaso2");

  localStorage.removeItem("idCuestionarioSolicitud");
  localStorage.removeItem("idResPrecalifica");
  localStorage.removeItem("idCredito");
  localStorage.removeItem("idActividadEconomica");

  const evaluarSolicitud = async () => {
    let data = await get("userCotizacionesConProcesoBanco", cookies.get("id"));
    if (data.cotizaciones.length > 0) {
      setCuentaConCotizaciones(true);
    } else {
      setCuentaConCotizaciones(false);
    }

    //JPB Evalua solicitudes
    let dataSolicitudes = await get("getInfoCalifica", cookies.get("id"));
    if (dataSolicitudes.statuscode === 200) {
      setCuentaConSolicitudes(true);
    } else {
      setCuentaConSolicitudes(false);
    }
  };

  //JPB Determina que se va a renderizar
  function evaluarCards() {
    if (!cuentaConCotizaciones && cuentaConSolicitudes) {
      return (
        <>
          <SinCotizacion />
          <ResumenCardsBancos />{" "}
        </>
      );
    } else if (cuentaConCotizaciones && !cuentaConSolicitudes) {
      return (
        <>
          <ResumenCotizaciones />
          <div className="sinSolicitudes">
            <SinSolicitudes />{" "}
          </div>
        </>
      );
    } else if (!cuentaConCotizaciones && !cuentaConSolicitudes) {
      return (
        <>
          <SinCotizacionYSolicitud />{" "}
        </>
      );
    } else if (cuentaConCotizaciones && cuentaConSolicitudes) {
      return (
        <>
          <ResumenCotizaciones />
          <br /><br />
          <ResumenCardsBancos />{" "}
          <br /><br />
        </>
      );
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    evaluarSolicitud();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // info();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1>Mi tablero</h1>
        {evaluarCards()}
      </div>
    </>
  );
}
