import '../../App.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Cookies from 'universal-cookie'
import { useEffect, useState } from 'react'
import { get } from '../../utils/http'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------- Pantalla donde se muestran los términos y condiciones ------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function TerminosCondiciones() {
  const cookie = new Cookies()
  const [msg,setMsg] = useState()

  const obtTerminosCondiciones = async() =>{
    let data = await get('obtValorConfiguracionPorId', 2)
    if(data.statuscode === 200){
      setMsg(data.configuraciones.valor)
    }
  }
  
  useEffect( () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtTerminosCondiciones()
  },[])

  let loggin=false
    return (
      <>
        {cookie.get('session') ? loggin===true : <Header/>}
        <div id="mostrarTexto" className="cont_msgGenerales" dangerouslySetInnerHTML={{__html: msg}}>
        </div>
        {cookie.get('session') ? loggin===true : <Footer/>}
      </>
    );
}
