import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "../../App.css";
import CrearCuenta from "../../images/CrearCuenta.jpg";
import {
  useDataPublic,
  useSetDataPublic,
} from "../../providers/dataPublic/PublicProvider";
import { ruleEmail, ruleContraseña } from "../../assets/validateRules";
import { post, get } from "../../utils/http";
import logo_morado from "../../images/logo_morado.svg";
import { obtEmpresaUrl } from "../../utils/global_functions";

// Pantalla: Crear cuenta sin simulación
export default function CrearCuentaSocios() {
  const [logo, setLogo] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [mostrarLogo, setMostrarLogo] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const { correo, statuscode } = useDataPublic();
  const actionContext = useSetDataPublic();
  const [emailR, setEmail] = useState(correo ? correo : "");
  const [nombreS, setNombre] = useState("");
  const [aPaterno, setAPaterno] = useState("");
  const [aMaterno, setAMaterno] = useState("");

  const [passwordR, setPassword] = useState("");
  const cookie = new Cookies();
  const [validarReglaContraseña, setValidarReglaContraseña] = useState();

  //!En caso de que exista un parametro en la url, se desencripta el id de la empresa
  const obtIdEmpresa = async () => {
    //Comprobamos si ya tenemos el hash de la empresa
    if (localStorage.getItem("idEmpresaHash") != null) {
      obtInfonEmpresa(localStorage.getItem("idEmpresaHash"));
      setMostrarLogo(true);
    } else {
      //Sino entonces lo obtenemos
      const queryString = window.location.search.substr(1);
      if (queryString !== "") {
        let desencriptar = await get("desencriptarLink", queryString);
        if (desencriptar.statuscode === 200) {
          const cadena = desencriptar.link;
          // Busca el primer número
          const primerNumero = cadena.match(/empresa=(\d+)/);
          if (primerNumero) {
            const idEmpresa = primerNumero[1];
            localStorage.setItem("idEmpresaHash", idEmpresa);
            obtInfonEmpresa(idEmpresa);
            setMostrarLogo(true);
          } else {
            // console.log("Número de empresa no encontrado");
          }
        }
      } else {
        setMostrarLogo(false);
      }
    }
  };

  //!Consultar información de la empresa
  const obtInfonEmpresa = async (idEmpresa = 0) => {
    if (idEmpresa > 0) {
      let empresa = await get("getEmpresaSocio", idEmpresa);
      if (empresa.statuscode === 200) {
        // console.log(empresa);
        //Para generar la imagen
        const datosBinarios = atob(empresa.file.fileContents);
        const arrayDeBytes = new Uint8Array(datosBinarios.length);
        for (let i = 0; i < datosBinarios.length; i++) {
          arrayDeBytes[i] = datosBinarios.charCodeAt(i);
        }
        const imagenBlob = new Blob([arrayDeBytes], { type: "image/png" });
        const imagenUrl = URL.createObjectURL(imagenBlob);
        // La solicitud fue exitosa, establecer la URL de la imagen en el estado
        setLogo(imagenUrl);
        setNombreEmpresa(empresa.empresa.nombre);
      } else {
        setLogo(CrearCuenta);
      }
    }
  };

  // Función para manejar el registro de usuario
  const signUp = async () => {
    // 16-02-2024 Para asignar la empresa de remax si existe

    let idEmpresa = null;
    if (
      localStorage.getItem("idEmpresaSocio") !== undefined &&
      localStorage.getItem("idEmpresaSocio") != null
    ) {
      idEmpresa = localStorage.getItem("idEmpresaSocio");
    } else {
      idEmpresa = localStorage.getItem("idEmpresaHash");
    }

    if (validarReglaContraseña === true) {
      let data = {
        correo: emailR,
        nombre: nombreS.toString().trimRight(),
        paterno: aPaterno.toString().trimRight(),
        materno: aMaterno.toString().trimRight(),
        password: passwordR.toString().trimRight(),
        idEmpresaSocio:
          idEmpresa !== undefined && idEmpresa != null ? idEmpresa : null,
      };

      let response = await post("newSocio", data);

      //actionContext({ statuscode: response.statuscode === undefined ? response.status : response.statuscode });
      //console.log(response);
      if (response.statuscode === 200) {
        let timerInterval;
        Swal.fire({
          title: "Iniciando sesión",
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          // Realizar acciones después de iniciar sesión
        });

        signIn();
      }
    }
  };

  // Función para iniciar sesión
  const signIn = async () => {
    let data = {
      correo: emailR,
      password: passwordR,
    };

    let response = await post("loginSocio", data);

    // console.log(response);
    if (response.statuscode === 200) {
      cookie.remove("sessionSocio", { path: "/" });
      cookie.remove("nombreSocio", { path: "/" });
      cookie.remove("correoSocio", { path: "/" });
      cookie.remove("idSocio", { path: "/" });

      cookie.set("sessionSocio", true, { path: "/" });
      cookie.set(
        "nombreSocio",
        response.datosSocio.nombre + " " + response.datosSocio.paterno,
        { path: "/" }
      );
      cookie.set("correoSocio", response.datosSocio.correo, { path: "/" });
      cookie.set("idSocio", response.datosSocio.idSocios, { path: "/" });

      //Eliminamos el hash de la empresa una vez que se registre o inicie sesión
      localStorage.removeItem("idEmpresaHash");
      localStorage.removeItem("idEmpresaSocio");
      window.location.href = "/panelSocios";
    } else {
      Swal.fire({
        title: "Usuario y/o contraseña incorrecta",
        text: "Verifica que la cuenta y la contraseña sean correctas",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  // Función para mostrar/ocultar la contraseña
  function verPassword() {
    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = "fa fa-eye-slash iconoOcultarPassword";
    } else {
      x.type = "password";
      icono.className = "fa fa-eye iconoOcultarPassword";
    }
  }

  // Efecto para desplazar al inicio de la página
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          <h1>
            Bienvenido al programa de socios {mostrarLogo ? nombreEmpresa : ""}
          </h1>
          <p>Crea tu cuenta para ver el estatus de tus clientes preferidos.</p>

          <form onSubmit={handleSubmit(signUp)}>
            <label htmlFor="nombre">Nombre(s)</label>
            <div className="relativePosition">
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={nombreS}
                onInput={(ev) => setNombre(ev.target.value)}
                {...register("nombre", { required: true })}
              />
            </div>
            {errors.nombre?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}

            <label htmlFor="aPaterno">Apellido paterno</label>
            <div className="relativePosition">
              <input
                type="text"
                id="aPaterno"
                name="aPaterno"
                value={aPaterno}
                onInput={(ev) => setAPaterno(ev.target.value)}
                {...register("aPaterno", { required: true })}
              />
            </div>
            {errors.aPaterno?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}

            <label htmlFor="aPaterno">Apellido materno</label>
            <div className="relativePosition">
              <input
                type="text"
                id="aMaterno"
                name="aMaterno"
                value={aMaterno}
                onInput={(ev) => setAMaterno(ev.target.value)}
                {...register("aMaterno", { required: true })}
              />
            </div>
            {errors.aMaterno?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}

            <label htmlFor="email">Correo electrónico</label>

            <input
              type="email"
              id="email"
              name="email"
              value={emailR}
              onInput={(ev) => setEmail(ev.target.value)}
              {...register("email", { required: true, validate: ruleEmail })}
            />

            {errors.email?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
            {errors.email && (
              <span className="span">El formato del correo no es válido</span>
            )}
            {statuscode === 400 && (
              <span className="span">Correo ya utilizado</span>
            )}

            <label htmlFor="password">Contraseña</label>

            <div className="relativePosition">
              <input
                type="password"
                id="password"
                name="password"
                value={passwordR}
                onInput={(ev) => {
                  let tempPassword = ev.target.value;
                  setValidarReglaContraseña(ruleContraseña(tempPassword));
                  setPassword(tempPassword);
                }}
                {...register("password", { required: true })}
              />
              <i
                id="iconoPassword"
                className="fa fa-eye iconoOcultarPassword"
                onClick={() => verPassword()}
              ></i>
            </div>
            {validarReglaContraseña === false && (
              <small className="span">
                Ingresar contraseña con al menos una mayúscula, un número, un
                carácter especial y mínimo 8 caracteres.
              </small>
            )}
            {errors.password?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}

            <div className="botones">
              <button type="submit" className="btn btn_morado">
                Crear cuenta
              </button>
              {/*<Link className="btn btn_bco" to="/inicioSocios">
                Iniciar sesión
              </Link>
              */}
            </div>

            <small>
              Tus datos personales son muy importantes para nosotros y tu
              seguridad es nuestra prioridad.
              <br /> Consulta nuestro &nbsp;
              <Link to="/avisoDePrivacidad" className="link">
                Aviso de privacidad
              </Link>
            </small>
          </form>
        </div>

        {mostrarLogo ? (
          <div className="col6">
            <div>
              <img
                src={logo}
                alt="Crear Cuenta Socios"
                className="logo_client"
              />
            </div>
          </div>
        ) : (
          <div className="col6 cont_img-index">
            <img src={CrearCuenta} alt="Crear Cuenta Socios" />
          </div>
        )}
      </div>
    </>
  );
}
