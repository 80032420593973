import { create } from "@incodetech/welcome";

// <---------- URL del servidor ---------->
const url = "https://saturn5.somee.com/"
//const url = "https://localhost:7079/"
//const url = "https://framelova.somee.com/"
//const url =  "https://api.saturn5.mx/"

// <---------- Datos API COPOMEX ---------->
const urlCP = "https://api.copomex.com/query/"
const tokenCP = "a0c243a5-e523-4f5b-be13-c2b24b38e7ac"

//<-------- Datos API paypal ------>
const base = "https://api-m.paypal.com";
const PAYPAL_CLIENT_ID = "AS3LWRfnFwIsgE4YGz7CL_NFKOuc_rzGKf2ETuJajKgd91lPN_hXUU9LtBmf1PJqeWkcnI-GX70vf5cl";
const PAYPAL_CLIENT_SECRET = "EPvi_buRsbI25lCh1rntW1LnZJQ_aqPm8EYbW3CtC-mGwonqWFivjTZqzEhyzn1mcdnTlN1Be-dGfGtn";

// <---------- Datos Incode -------------->
const urlIncodeAPI = "https://demo-api.incodesmile.com/";
const incodeAPIKey = "a73835f6b6992d4a2dd7539e8f760b80bd8f50dc";
export const incodeFlowId = "6685800953851f0bcb71de62";

export const incode = create({
  apiKey: incodeAPIKey,
  apiURL: urlIncodeAPI
});

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Función para enviar y obtener respuesta POST ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function post(endpoint, data) {
    return await fetch(url + endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json; charset=UTF-8" },
    })
        .then(response => { return response.json() })
        .catch(err => console.log(err))
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Función para enviar documentos y obtener respuesta POST ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function postDocs(endpoint, formData) {
    try {
        return await fetch(url + endpoint, {
            method: 'POST',
            body: formData
        }).then(response => { return response.json() })
            .catch(err => console.log(err));

    } catch (error) {
        console.error('Error al subir el archivo:', error);
    }
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Función para enviar y obtener respuesta GET ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function get(endpoint, data) {
    //console.log(url+endpoint+"/"+data);
    return await fetch(url + endpoint + "/" + data, {
        method: "GET",
        headers: { "Content-type": "application/json; charset=UTF-8" },
    })
        .then(response => { return response.json() })
        .catch(err => console.log(err))
}

export async function postDoc(endpoint, data) {
    const urlWithEndpoint = url + endpoint;
    //console.log(urlWithEndpoint);

    try {
        const response = await fetch(urlWithEndpoint, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" },
        });

        // Verifica si la respuesta es exitosa (status code 200)
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}




// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------ Función para enviar y obtener respuesta PUT ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function put(endpoint, data) {
    return await fetch(url + endpoint, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json; charset=UTF-8" },
    })
        .then(response => { return response.json() })
        .catch(err => console.log(err))
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Función para obtener respuesta del API COPOMEX para CP  ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function getCP(endpoint, data) {
    return await fetch(urlCP + endpoint + "/" + data + "?type=simplified&token=" + tokenCP, {
        method: "GET",
        headers: { "Content-type": "application/json; charset=UTF-8" },
    })
        .then(response => { return response.json() })
        .catch(err => console.log(err))
}


// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Función para obtener respuesta del API COPOMEX para CP  ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export async function postFile(endpoint, data) {
    const formData = new FormData();
    formData.append('file', data.file); // 'file' es el nombre del campo en el formulario

    return await fetch(url + endpoint, {
        method: "POST",
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .catch((err) => console.log(err));
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Función para mandar un delete  ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export async function deleteDocEndpoint(endpoint, data) {
    return await fetch(url + endpoint, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: { "Content-type": "application/json; charset=UTF-8" },
    })
        .then(response => { return response.json() })
        .catch(err => console.log(err))
}



//
// Endpoints paypal
//

export async function postPaypal(endpoint, data) {
    try {
        const response = await fetch(url + endpoint, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-type": "application/json; charset=UTF-8" },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (err) {
        console.error("Error in postPaypal:", err);
        throw err; // Rethrow the error to be caught in the onApprove function
    }
}
