import { AgGridReact } from "ag-grid-react";
//import React from 'react';
import React, { useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../App.css";
import btnCopiar from "./Botones";

export function TablaBancosNoSelect({ arrData }) {
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMovable: true, // Evitar que los usuarios arrastren esta columna
    };
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const array = arrData;
  //console.log(array);
  for (let i = 0; i < array.length; i++) {
    const nombreCompleto = `${array[i].nombre} ${array[i].paterno} ${array[i].materno}`;
    array[i].nombreCompleto = nombreCompleto;
  }

  if (!array || array.length === 0) {
    return (
      <div>
        <p>No hay clientes.</p>
      </div>
    );
  }
  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: {
      // for filter panel
      page: "Pagina",
      more: "Mas",
      to: "a",
      of: "de",
      next: "Siguente",
      last: "Último",
      first: "Primero",
      previous: "Anteror",
      loadingOoo: "Cargando...",

      // for set filter
      selectAll: "Seleccionar Todo",
      searchOoo: "Buscar...",
      blanks: "En blanco",

      // for number filter and text filter
      filterOoo: "Filtrar",
      applyFilter: "Aplicar Filtro...",
      equals: "Igual",
      notEqual: "No Igual",

      // for number filter
      lessThan: "Menos que",
      greaterThan: "Mayor que",
      lessThanOrEqual: "Menos o igual que",
      greaterThanOrEqual: "Mayor o igual que",
      inRange: "En rango de",

      // for text filter
      contains: "Contiene",
      notContains: "No contiene",
      startsWith: "Empieza con",
      endsWith: "Termina con",

      // filter conditions
      andCondition: "Y",
      orCondition: "O",

      // the header of the default group column
      group: "Grupo",

      // tool panel
      columns: "Columnas",
      filters: "Filtros",
      valueColumns: "Valos de las Columnas",
      pivotMode: "Modo Pivote",
      groups: "Grupos",
      values: "Valores",
      pivots: "Pivotes",
      toolPanelButton: "BotonDelPanelDeHerramientas",

      // other
      noRowsToShow: "No hay filas para mostrar",

      // enterprise menu
      pinColumn: "Columna Pin",
      valueAggregation: "Agregar valor",
      autosizeThiscolumn: "Autoajustar esta columna",
      autosizeAllColumns: "Ajustar todas las columnas",
      groupBy: "agrupar",
      ungroupBy: "desagrupar",
      resetColumns: "Reiniciar Columnas",
      expandAll: "Expandir todo",
      collapseAll: "Colapsar todo",
      toolPanel: "Panel de Herramientas",
      export: "Exportar",
      csvExport: "Exportar a CSV",
      excelExport: "Exportar a Excel (.xlsx)",
      excelXmlExport: "Exportar a Excel (.xml)",

      // enterprise menu pinning
      pinLeft: "Pin Izquierdo",
      pinRight: "Pin Derecho",

      // enterprise menu aggregation and status bar
      sum: "Suman",
      min: "Minimo",
      max: "Maximo",
      none: "nada",
      count: "contar",
      average: "promedio",

      // standard menu
      copy: "Copiar",
      copyWithHeaders: "Copiar con cabeceras",
      paste: "Pegar",
    },
  };

  function customValueFormatter(params) {
    const value = params.value;
    if (value === 1 || value == null) {
      return "Banco no seleccionado";
    } else {
      return "Banco seleccionado";
    }
  }

  function tipoCredito(params) {
    switch (params.value) {
      case 1:
        return "Maximo";
        break;
      case 2:
        return "Minimo";
        break;
      case 3:
        return "Optimo";
        break;
      default:
        break;
    }
  }

  const columnDefs = [
    { headerName: "Cliente", field: "nombreCompleto" },
    { headerName: "Enganche", field: "enganche", valueFormatter: formatNumber },
    {
      headerName: "Monto solicitado por el cliente",
      field: "montoSolicitado",
      valueFormatter: formatNumber,
    },
    {
      headerName: "Crédito",
      field: "tipo",
      valueFormatter: tipoCredito,
    },
    {
      headerName: "Monto máximo aprobado por banco",
      field: "prestamoMax",
      valueFormatter: formatNumber,
    },
    { headerName: "Banco", field: "productoNombre" },
  ];

  // Función para formatear números
  function formatNumber(params) {
    if (typeof params.value === "number") {
      return (
        "$ " +
        params.value.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
    return params.value;
  }

  return (
    <div className="ag-theme-material" style={{ height: 400, width: 1200 }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={array}
        defaultColDef={defaultColDef}
        statusBar={statusBar}
        gridOptions={gridOptions}
      />
    </div>
  );
}

export function Tabla({ arrData }) {
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMovable: true, // Evitar que los usuarios arrastren esta columna
    };
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const array = arrData;
  //console.log(array);
  for (let i = 0; i < array.length; i++) {
    const nombreCompleto = `${array[i].nombre} ${array[i].paterno} ${array[i].materno}`;
    array[i].nombreCompleto = nombreCompleto;
  }

  if (!array || array.length === 0) {
    return (
      <div>
        <p>No hay clientes.</p>
      </div>
    );
  }

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: {
      // for filter panel
      page: "Pagina",
      more: "Mas",
      to: "a",
      of: "de",
      next: "Siguente",
      last: "Último",
      first: "Primero",
      previous: "Anteror",
      loadingOoo: "Cargando...",

      // for set filter
      selectAll: "Seleccionar Todo",
      searchOoo: "Buscar...",
      blanks: "En blanco",

      // for number filter and text filter
      filterOoo: "Filtrar",
      applyFilter: "Aplicar Filtro...",
      equals: "Igual",
      notEqual: "No Igual",

      // for number filter
      lessThan: "Menos que",
      greaterThan: "Mayor que",
      lessThanOrEqual: "Menos o igual que",
      greaterThanOrEqual: "Mayor o igual que",
      inRange: "En rango de",

      // for text filter
      contains: "Contiene",
      notContains: "No contiene",
      startsWith: "Empieza con",
      endsWith: "Termina con",

      // filter conditions
      andCondition: "Y",
      orCondition: "O",

      // the header of the default group column
      group: "Grupo",

      // tool panel
      columns: "Columnas",
      filters: "Filtros",
      valueColumns: "Valos de las Columnas",
      pivotMode: "Modo Pivote",
      groups: "Grupos",
      values: "Valores",
      pivots: "Pivotes",
      toolPanelButton: "BotonDelPanelDeHerramientas",

      // other
      noRowsToShow: "No hay filas para mostrar",

      // enterprise menu
      pinColumn: "Columna Pin",
      valueAggregation: "Agregar valor",
      autosizeThiscolumn: "Autoajustar esta columna",
      autosizeAllColumns: "Ajustar todas las columnas",
      groupBy: "agrupar",
      ungroupBy: "desagrupar",
      resetColumns: "Reiniciar Columnas",
      expandAll: "Expandir todo",
      collapseAll: "Colapsar todo",
      toolPanel: "Panel de Herramientas",
      export: "Exportar",
      csvExport: "Exportar a CSV",
      excelExport: "Exportar a Excel (.xlsx)",
      excelXmlExport: "Exportar a Excel (.xml)",

      // enterprise menu pinning
      pinLeft: "Pin Izquierdo",
      pinRight: "Pin Derecho",

      // enterprise menu aggregation and status bar
      sum: "Suman",
      min: "Minimo",
      max: "Maximo",
      none: "nada",
      count: "contar",
      average: "promedio",

      // standard menu
      copy: "Copiar",
      copyWithHeaders: "Copiar con cabeceras",
      paste: "Pegar",
    },
  };

  function customValueFormatter(params) {
    //const value = params.value;
    //if (value === 1 || value == null) {
    //  return 'Banco no seleccionado';
    //} else {
    //  return 'Banco seleccionado';
    //}

    return "Banco seleccionado";
  }

  const columnDefs = [
    { headerName: "Cliente", field: "nombreCompleto" },
    { headerName: "Enganche", field: "enganche", valueFormatter: formatNumber },
    {
      headerName: "Montos del credito",
      field: "prestamoMax",
      valueFormatter: formatNumber,
    },
    { headerName: "Producto", field: "productoNombre" },
    {
      headerName: "Estatus",
      field: "noTieneBanco",
      valueFormatter: customValueFormatter,
    },
    //{
    //  headerName: 'Estatus',
    //  field: 'estatus',
    //  cellRenderer: (params) => {
    // Aquí puedes crear y retornar tu componente personalizado
    // Puedes usar `params.value` para obtener el valor de la celda actual
    // y luego crear tu componente basado en ese valor.
    // Por ejemplo, si el estatus es "Activo", puedes renderizar un icono verde,
    // y si es "Inactivo", puedes renderizar un icono rojo.
    // A continuación, se muestra un ejemplo simplificado utilizando texto:
    //    return <span>{params.value}</span>;
    //  }
    //}
    // Agrega más columnas según tus datos
  ];

  // Función para formatear números
  function formatNumber(params) {
    if (typeof params.value === "number") {
      // Personaliza el formato como desees, por ejemplo, formato de número con 2 decimales
      return (
        "$ " +
        params.value.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
    // Si no es un número, simplemente devuelve el valor original
    return params.value;
  }

  return (
    <div className="ag-theme-material" style={{ height: 400, width: 1200 }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={array}
        defaultColDef={defaultColDef}
        statusBar={statusBar}
        gridOptions={gridOptions}
      />
    </div>
  );
}

export function TablaInvitaciones({ arrData }) {
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMovable: true, // Evitar que los usuarios arrastren esta columna
    };
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const array = arrData;

  for (let i = 0; i < array.length; i++) {
    const nombreCompleto = `${array[i].nombre} ${array[i].paterno} ${array[i].materno}`;
    array[i].nombreCompleto = nombreCompleto;
  }

  if (!array || array.length === 0) {
    return (
      <div>
        <p>No hay invitaciones.</p>
      </div>
    );
  }

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: {
      // for filter panel
      page: "Pagina",
      more: "Mas",
      to: "a",
      of: "de",
      next: "Siguente",
      last: "Último",
      first: "Primero",
      previous: "Anteror",
      loadingOoo: "Cargando...",

      // for set filter
      selectAll: "Seleccionar Todo",
      searchOoo: "Buscar...",
      blanks: "En blanco",

      // for number filter and text filter
      filterOoo: "Filtrar",
      applyFilter: "Aplicar Filtro...",
      equals: "Igual",
      notEqual: "No Igual",

      // for number filter
      lessThan: "Menos que",
      greaterThan: "Mayor que",
      lessThanOrEqual: "Menos o igual que",
      greaterThanOrEqual: "Mayor o igual que",
      inRange: "En rango de",

      // for text filter
      contains: "Contiene",
      notContains: "No contiene",
      startsWith: "Empieza con",
      endsWith: "Termina con",

      // filter conditions
      andCondition: "Y",
      orCondition: "O",

      // the header of the default group column
      group: "Grupo",

      // tool panel
      columns: "Columnas",
      filters: "Filtros",
      valueColumns: "Valos de las Columnas",
      pivotMode: "Modo Pivote",
      groups: "Grupos",
      values: "Valores",
      pivots: "Pivotes",
      toolPanelButton: "BotonDelPanelDeHerramientas",

      // other
      noRowsToShow: "No hay filas para mostrar",

      // enterprise menu
      pinColumn: "Columna Pin",
      valueAggregation: "Agregar valor",
      autosizeThiscolumn: "Autoajustar esta columna",
      autosizeAllColumns: "Ajustar todas las columnas",
      groupBy: "agrupar",
      ungroupBy: "desagrupar",
      resetColumns: "Reiniciar Columnas",
      expandAll: "Expandir todo",
      collapseAll: "Colapsar todo",
      toolPanel: "Panel de Herramientas",
      export: "Exportar",
      csvExport: "Exportar a CSV",
      excelExport: "Exportar a Excel (.xlsx)",
      excelXmlExport: "Exportar a Excel (.xml)",

      // enterprise menu pinning
      pinLeft: "Pin Izquierdo",
      pinRight: "Pin Derecho",

      // enterprise menu aggregation and status bar
      sum: "Suman",
      min: "Minimo",
      max: "Maximo",
      none: "nada",
      count: "contar",
      average: "promedio",

      // standard menu
      copy: "Copiar",
      copyWithHeaders: "Copiar con cabeceras",
      paste: "Pegar",
    },
  };

  const columnDefs = [
    { headerName: "Nombre", field: "nombreCompleto" },
    {
      headerName: "Teléfono",
      field: "telefono",
      valueFormatter: formatearNumeroTelefono,
    },
    { headerName: "Correo electrónico", field: "correo" },
    {
      headerName: "Estatus",
      field: "estatus",
      valueFormatter: determinarEstatus,
    },
    {
      headerName: "Invitación",
      field: "linkInvitacion",
      width: 500,
      editable: true,
    },
    // Agrega más columnas según tus datos

    // Agregar una columna con un componente de botón
    //TODO: Agregar componente boton, ahorita no esta funcionando
    //{
    //   headerName: 'Acciones',
    //   cellRendererFramework: btnCopiar, // Reemplaza BotonComponente con el nombre de tu componente de botón
    //  width: 200, // Ajusta el ancho del botón
    //  cellRendererParams: {
    //    Invitación: 'linkInvitacion', // Nombre del campo de la columna 'Invitación'
    //  },
    //},
    // Agrega más columnas según tus datos
  ];

  // Función para determinar el estatus de cada invitacion
  function determinarEstatus(params) {
    if (typeof params.value === "number") {
      let estado = "";
      switch (params.value) {
        case 1:
          estado = "Enviado";
          break;

        case 2:
          estado = "Link abierto";
          break;

        case 3:
          estado = "Registrado";
          break;

        default:
          estado = "Sin información";
          break;
      }
      return estado;
    }
    // Si no es un número, simplemente devuelve el valor original
    return params.value;
  }

  // Función para formatear telefonos
  function formatearNumeroTelefono(params) {
    // Eliminar todos los caracteres no numéricos
    const numeroLimpio = params.value.replace(/\D/g, "");

    // Verificar si el número tiene el código de área (10 dígitos) o no (7 dígitos)
    if (numeroLimpio.length === 10) {
      // Formato con código de área (xxx) xxx-xxxx
      return `(${numeroLimpio.slice(0, 3)}) ${numeroLimpio.slice(
        3,
        6
      )}-${numeroLimpio.slice(6)}`;
    } else if (numeroLimpio.length === 7) {
      // Formato sin código de área xxx-xxxx
      return `${numeroLimpio.slice(0, 3)}-${numeroLimpio.slice(3)}`;
    } else {
      // Si el número no tiene 7 ni 10 dígitos, devolver el número original sin formato
      return params.value;
    }
  }

  return (
    <div className="ag-theme-material" style={{ height: 400 }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={array}
        defaultColDef={defaultColDef}
        statusBar={statusBar}
        gridOptions={gridOptions}
      />
    </div>
  );
}

export function TablaComisiones({ arrData }) {
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMovable: true, // Evitar que los usuarios arrastren esta columna
    };
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
        { statusPanel: "agSelectedRowCountComponent" },
        { statusPanel: "agAggregationComponent" },
      ],
    };
  }, []);

  const array = arrData;

  for (let i = 0; i < array.length; i++) {
    const nombreCompleto = `${array[i].nombre} ${array[i].paterno} ${array[i].materno}`;
    array[i].nombreCompleto = nombreCompleto;
  }

  if (!array || array.length === 0) {
    return (
      <div>
        <p>No hay comisiones.</p>
      </div>
    );
  }

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: {
      // for filter panel
      page: "Pagina",
      more: "Mas",
      to: "a",
      of: "de",
      next: "Siguente",
      last: "Último",
      first: "Primero",
      previous: "Anteror",
      loadingOoo: "Cargando...",

      // for set filter
      selectAll: "Seleccionar Todo",
      searchOoo: "Buscar...",
      blanks: "En blanco",

      // for number filter and text filter
      filterOoo: "Filtrar",
      applyFilter: "Aplicar Filtro...",
      equals: "Igual",
      notEqual: "No Igual",

      // for number filter
      lessThan: "Menos que",
      greaterThan: "Mayor que",
      lessThanOrEqual: "Menos o igual que",
      greaterThanOrEqual: "Mayor o igual que",
      inRange: "En rango de",

      // for text filter
      contains: "Contiene",
      notContains: "No contiene",
      startsWith: "Empieza con",
      endsWith: "Termina con",

      // filter conditions
      andCondition: "Y",
      orCondition: "O",

      // the header of the default group column
      group: "Grupo",

      // tool panel
      columns: "Columnas",
      filters: "Filtros",
      valueColumns: "Valos de las Columnas",
      pivotMode: "Modo Pivote",
      groups: "Grupos",
      values: "Valores",
      pivots: "Pivotes",
      toolPanelButton: "BotonDelPanelDeHerramientas",

      // other
      noRowsToShow: "No hay filas para mostrar",

      // enterprise menu
      pinColumn: "Columna Pin",
      valueAggregation: "Agregar valor",
      autosizeThiscolumn: "Autoajustar esta columna",
      autosizeAllColumns: "Ajustar todas las columnas",
      groupBy: "agrupar",
      ungroupBy: "desagrupar",
      resetColumns: "Reiniciar Columnas",
      expandAll: "Expandir todo",
      collapseAll: "Colapsar todo",
      toolPanel: "Panel de Herramientas",
      export: "Exportar",
      csvExport: "Exportar a CSV",
      excelExport: "Exportar a Excel (.xlsx)",
      excelXmlExport: "Exportar a Excel (.xml)",

      // enterprise menu pinning
      pinLeft: "Pin Izquierdo",
      pinRight: "Pin Derecho",

      // enterprise menu aggregation and status bar
      sum: "Suman",
      min: "Minimo",
      max: "Maximo",
      none: "nada",
      count: "contar",
      average: "promedio",

      // standard menu
      copy: "Copiar",
      copyWithHeaders: "Copiar con cabeceras",
      paste: "Pegar",
    },
  };

  function customValueFormatter(params) {
    const value = params.value;
    if (value === 1 || value == null) {
      return "Banco no seleccionado";
    } else {
      return "Banco seleccionado";
    }
  }

  const columnDefs = [
    //{ headerName: 'ID_SOCIO', field: 'idSocio'  },
    //{ headerName: 'ID_EMPRESA', field: 'idEmpresaSocio'  },
    //{ headerName: 'ID_CLIENTE', field: 'idUsuario'  },
    //{ headerName: 'ID_CREDITO', field: 'idResPrecalifica'  },
    { headerName: "NOMBRE CLIENTE", field: "nombreCliente" },
    { headerName: "NOMBRE PRODUCTO", field: "nombreProducto" },
    { headerName: "NOMBRE BANCO", field: "nombreBanco" },
    {
      headerName: "Monto de cierre",
      field: "montoCierre",
      valueFormatter: formatNumber,
    },
    {
      headerName: "fecha de cierre",
      field: "fechaCierre",
      valueFormatter: formatDate,
    },
    { headerName: "Comision", field: "comision", valueFormatter: formatNumber },
    {
      headerName: "Fecha Pago Comision",
      field: "fechaPagoComision",
      valueFormatter: formatDate,
    },
  ];

  // Función para formatear números
  function formatNumber(params) {
    if (typeof params.value === "number") {
      // Personaliza el formato como desees, por ejemplo, formato de número con 2 decimales
      return (
        "$ " +
        params.value.toLocaleString("es-MX", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
    // Si no es un número, simplemente devuelve el valor original
    return params.value;
  }

  // Función para formatear fechas
  function formatDate(params) {
    // Verifica si el valor es una cadena de fecha
    if (typeof params.value === "string") {
      // Formatea la cadena de fecha según tus preferencias
      const formattedDate = new Date(params.value).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return formattedDate;
    }
    // Si no es una cadena de fecha, simplemente devuelve el valor original
    return params.value;
  }

  return (
    <div
      id="tablaComisiones"
      className="ag-theme-material"
      style={{ height: 400, width: 1200 }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={array}
        defaultColDef={defaultColDef}
        statusBar={statusBar}
        gridOptions={gridOptions}
      />
    </div>
  );
}
