import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import * as ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ruleCP,
  ruleEmail,
  ruleCURP,
  ruleRFC,
  ruleTelefono,
  ruleNacimiento,
} from "../../assets/validateRules";
import { set, useForm } from "react-hook-form";

export default function InfoPersonalForm() {
  //Para almacenar informacion personal
  const [curp, setCurp] = useState("");
  const [sexo, setSexo] = useState("");
  const [seguroSocial, setSeguroSocial] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [gradoMaxEstudios, setGradoMaxEstudios] = useState("");
  const [numDepEco, setNumDepEco] = useState("");
  const [tipoVivienda, setTipoVivienda] = useState("");
  const [aniosVivienda, setAniosVivienda] = useState("");

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //! Para asignar los anños de vivienda
  const opcionesAniosVivienda = [];
  for (let i = 1; i <= 50; i++) {
    opcionesAniosVivienda.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const onSubmit = (data) => {
    // console.log(data); // Aquí puedes hacer algo con los datos del formulario
    // console.log(curp);
  };

  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion">
          {"<"} Regresar a documentación
        </a>

        <h1 className="titt">Información Personal</h1>

        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="Resum">
            <div className="header">
              <h3>Personales</h3>
            </div>

            <hr />

            <div className="cont_flex cont-form ">
              {/* CURP */}
              <div className="col3 campo-form ">
                <label>CURP</label>
                <input
                  type="text"
                  id="curp"
                  name="curp"
                  {...register("curp", { required: true, validate: ruleCURP })}
                  maxLength={18}
                  value={curp || ""}
                  onInput={(ev) => {
                    setCurp(ev.target.value);
                  }}
                />
                {errors.curp?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* GENERO */}
              <div className="col3 campo-form estado">
                <label>Género</label>
                <select
                  name="sexo"
                  id="sexo"
                  {...register("sexo", { required: true })}
                  value={sexo || ""}
                  onInput={(ev) => {
                    setSexo(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="true">Masculino</option>
                  <option value="false">Femenino</option>
                </select>
                {errors.sexo?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Seguro social */}
              <div className="col3 campo-form">
                <label>Número de seguridad social</label>
                <input
                  type="tel"
                  id="numeroSeguroSocial"
                  name="numeroSeguroSocial"
                  maxLength={12}
                  {...register("seguroSocial", { required: true })}
                  value={seguroSocial || ""}
                  onInput={(ev) => {
                    setSeguroSocial(ev.target.value);
                  }}
                />
                <small>
                  <a
                    href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS;JSESSIONIDASEGEXTERNO=QVtxJ5QWWr9Y0NGI725WkKIErOaz4Rr8OtbSIHTlf91Hl7neHlaW!806955352"
                    target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
                    rel="noopener noreferrer" // Añadimos estos atributos de seguridad
                  >
                    Si no lo recuerdas, dar click aquí.
                  </a>
                </small>
                {errors.seguroSocial?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Estado civil */}
              <div className="col3 campo-form estado ">
                <label>Estado civil </label>
                <select
                  name="estadoCivil>"
                  id="estadoCivil"
                  {...register("estadoCivil", { required: true })}
                  value={estadoCivil || ""}
                  onInput={(ev) => {
                    setEstadoCivil(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Soltero</option>
                  <option value="2">Unión libre</option>
                  <option value="3">Sociedad de Convivencia</option>
                  <option value="4">Casado (separación de Bienes)</option>
                  <option value="5">Casado (Sociedad Coyugal)</option>
                  <option value="6">Viudo</option>
                  <option value="7">Divorciado</option>
                </select>
                {errors.estadoCivil?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Grado máximo de estudios*/}
              <div className="col3 campo-form estado ">
                <label>Grado máximo de estudios</label>
                <select
                  name="gradoMaxEstudios"
                  id="gradoMaxEstudios"
                  {...register("gradoMaxEstudios", { required: true })}
                  value={gradoMaxEstudios || ""}
                  onInput={(ev) => {
                    setGradoMaxEstudios(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Primaria</option>
                  <option value="2">Secundaria</option>
                  <option value="3">Preparatoria</option>
                  <option value="4">Técnico</option>
                  <option value="5">Licenciatura</option>
                  <option value="6">Postgrado</option>
                </select>
                {errors.gradoMaxEstudios?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* Número de dependientes económicos */}
              <div className="col3 campo-form estado ">
                <label>Número de dependientes económicos</label>
                <select
                  name="numDepEco>"
                  id="numDepEco"
                  {...register("numDepEco", { required: true })}
                  value={numDepEco || ""}
                  onInput={(ev) => {
                    setNumDepEco(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6 o más</option>
                </select>
                {errors.numDepEco?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* ¿Que tipo de vivienda habita?*/}
              <div className="col3 campo-form estado ">
                <label>¿Que tipo de vivienda habita?</label>
                <select
                  name="tipoVivienda"
                  id="tipoVivienda"
                  {...register("tipoVivienda", { required: true })}
                  value={tipoVivienda || ""}
                  onInput={(ev) => {
                    setTipoVivienda(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Propia</option>
                  <option value="2">Hipotecada</option>
                  <option value="3">Rentada</option>
                  <option value="4">De un familiar</option>
                </select>
                {errors.tipoVivienda?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/* ¿Años que a vivido en dicha vivienda? */}
              <div className="col3 campo-form estado ">
                <label>¿Años que a vivido en dicha vivienda?</label>
                <select
                  name="aniosVivienda>"
                  id="aniosVivienda"
                  {...register("aniosVivienda", { required: true })}
                  value={aniosVivienda || ""}
                  onInput={(ev) => {
                    setAniosVivienda(ev.target.value);
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  {opcionesAniosVivienda}
                </select>
                {errors.aniosVivienda?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias de Productos</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <div className="header">
                <h3>Referencia de crédito 1</h3>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo cuenta</label>
                <select name="tipoCuenta>" id="tipoCuenta">
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Hipoteca</option>
                  <option value="2">Crédito Auto</option>
                  <option value="3">Crédito Personal</option>
                  <option value="4">Tarjeta de Crédito</option>
                </select>
              </div>

              <div className="col3 campo-form">
                <label>Numero de Tarjeta o Crédito </label>
                <input type="text" id="numeroCuenta" name="numeroCuenta" />
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) del Crédito </label>
                <input
                  type="text"
                  id="institucionCredito"
                  name="institucionCredito"
                />
              </div>

              <div className="header">
                <h3>Referencias de producto de débito </h3>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) de la cuenta</label>
                <input
                  type="text"
                  id="intitutoCapacitacion"
                  name="intitutoCapacitacion"
                />
              </div>

              {
                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo de cuenta de débito</label>
                  <select
                    name="tipoCuentaCapacitacion>"
                    id="tipoCuentaCapacitacion"
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Inversiones</option>
                    <option value="2">Depósitos</option>
                  </select>
                </div>
              }

              <div className="header">
                <h3>Referencia de crédito 2</h3>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo cuenta </label>
                <select name="tipoCuentaP2" id="tipoCuentaP2">
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Hipoteca</option>
                  <option value="2">Crédito Auto</option>
                  <option value="3">Crédito Personal</option>
                  <option value="3">Tarjeta de Crédito</option>
                </select>
              </div>

              <div className="col3 campo-form">
                <label>Numero de Tarjeta o Crédito </label>
                <input type="text" id="numCuentaP2" name="numCuentaP2" />
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) del Crédito </label>
                <input
                  type="text"
                  id="institutoCredP2"
                  name="institutoCredP2"
                />
              </div>

              <div className="header">
                <h3>Referencias de producto de débito 2</h3>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) de la cuenta</label>
                <input
                  type="text"
                  id="insitucionCapacitacionP2"
                  name="insitucionCapacitacionP2"
                />
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de cuenta de débito</label>
                <select
                  name="tipoCuentaCapacitacionP2"
                  id="tipoCuentaCapacitacionP2"
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Inversiones</option>
                  <option value="2">Depósitos</option>
                </select>
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <div className="header">
                <h3>Referencia Familiar</h3>
              </div>
              <div className="col3 campo-form">
                <label>Nombre</label>
                <input type="text" id="nombre" name="nombre" />
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input type="text" id="aPaterno" name="aPaterno" />
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno</label>
                <input type="text" id="aMaterno" name="aMaterno" />
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco</label>
                <select name="relacion>" id="relacion">
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Otro Familiar</option>
                  <option value="3">Amigo</option>
                </select>
              </div>
              <div className="col3 campo-form" id="delegacion">
                <label>Teléfono</label>
                <input type="tel" id="telefono" name="telefono" />
              </div>
              <div className="col3 campo-form" id="colonia">
                <label>E-mail (opcional)</label>
                <input type="text" id="email" name="email" />
              </div>
              <div className="header">
                <h3>Referencia No Familiar </h3>
              </div>

              <div className="col3 campo-form">
                <label>Nombre</label>
                <input type="text" id="nombreP2" name="nombreP2" />
              </div>

              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input type="text" id="app2" name="apmp2" />
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno</label>
                <input type="text" id="apmp2" name="apmp2" />
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco</label>
                <select name="parentescoP2>" id="parentescoP2">
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Otro Familiar</option>
                  <option value="3">Amigo</option>
                </select>
              </div>
              <div className="col3 campo-form" id="delegacion">
                <label>Teléfono</label>
                <input type="tel" id="telp2" name="telp2" />
              </div>
              <div className="col3 campo-form" id="colonia">
                <label>E-mail (opcional)</label>
                <input type="text" id="emailp2" name="emailp2" />
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Ingresos y patrimonio</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <div className="header">
                <h3>Ingresos</h3>
              </div>
              <div className="col3 campo-form">
                <label>Ingresos Mensuales Brutos</label>
                <input type="text" id="sueldo" name="sueldo" />
              </div>
              <div className="col3 campo-form">
                <label>Otros ingresos</label>
                <input type="tel" id="otroIngreso" name="otroIngreso" />
              </div>
              <div className="header">
                <h3>Patrimonio</h3>
              </div>
              <div className="col3 campo-form">
                <label>Valor de Automoviles $$</label>
                <input type="tel" id="autoValor" name="autoValor" />
              </div>
              <div className="col3 campo-form">
                <label>Valor de la vivienda Actual $$</label>
                <input
                  type="tel"
                  id="viviendoActualValor"
                  name="viviendoActualValor"
                />
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Beneficiario</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <div className="col3 campo-form">
                <label>Nombre </label>
                <input type="tel" id="nombre" name="nombre" />
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input type="tel" id="aPaterno" name="aPaterno" />
              </div>
              <div className="col3 campo-form">
                <label>Apellido materno</label>
                <input type="tel" id="aMaterno" name="aMaterno" />
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco</label>
                <select name="relacion>" id="relacion">
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Otro Familiar</option>
                  <option value="3">Amigo</option>
                </select>
              </div>
              <div className="col3 campo-form">
                <label>Teléfono</label>
                <input type="tel" id="telefono" name="telefono" />
              </div>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <a className="btn btn_bco  campo-form " href="/informacion">
                {" "}
                Regresar
              </a>
              <button type="submit" className="btn btn_morado campo-form">
                Guardar
              </button>
            </div>
          </div>

          <div className="header">
            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>
        </form>
      </div>
    </>
  );
}
