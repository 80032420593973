import { Navigate, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  desencriptarToken,
  obtInformacionUsuarioRemax,
  obtInformacionUsuarioSocioRemax,
} from "../utils/global_functions";
import Cookies from "universal-cookie";

export const Auth = ({ session, children, path = "/" }) => {
  if (!session) return <Navigate to="/"></Navigate>;
  return <Outlet />;
};

export const NoAuth = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/home"></Navigate>;
  return <Outlet />;
};

export const NoAuthSocios = ({ session, children, path = "/" }) => {
  if (session) return <Navigate to="/panelSocios"></Navigate>;
  return <Outlet />;
};

/*
export const AuthSocio = ({session,children,path= '/inicioSocios'}) => {

    if( !session ) return <Navigate to='/inicioSocios'></Navigate>
    return <Outlet/>
}*/

export const AuthSocio = ({ session, path = "/inicioSocios" }) => {
  const [verificacionCompleta, setVerificacionCompleta] = useState(false);
  const cookie = new Cookies();

  const realizarVerificacion = async () => {
    try {
      // Obtener la URL actual
      const urlActual = window.location.href;

      // Obtener los parámetros de la URL
      const url = new URL(urlActual);

      // Imprime la cadena de búsqueda completa
      //console.log("Cadena de búsqueda:", url.search);

      // Obtener el valor del parámetro "token"
      const token = url.searchParams.get("token");
      //console.log(token);

      if (token) {
        let tokenDesencriptado = await desencriptarToken(token);
        //console.log(tokenDesencriptado);

        if (tokenDesencriptado !== false) {
          // Utilizar una expresión regular para extraer solo los dígitos hasta que aparezca la primera letra
          const soloDigitos = tokenDesencriptado.match(/^\d+/);

          // Si soloDigitos no es nulo, obtienes el primer elemento (que sería la coincidencia de dígitos)
          const numeros = soloDigitos ? soloDigitos[0] : null;
          //console.log(numeros);

          //let infoUser = await obtInformacionUsuarioRemax(numeros);
          let infoUser = await obtInformacionUsuarioSocioRemax(numeros);

          if (infoUser !== false) {
            cookie.remove("sessionSocio", { path: "/" });
            cookie.remove("nombreSocio", { path: "/" });
            cookie.remove("correoSocio", { path: "/" });
            cookie.remove("idSocio", { path: "/" });
            cookie.remove("remax", { path: "/" });

            //console.log(response);
            cookie.set("remax", true, { path: "/" });
            cookie.set("sessionSocio", true, { path: "/" });
            cookie.set(
              "nombreSocio",
              infoUser.nombre + " " + infoUser.paterno,
              { path: "/" }
            );
            cookie.set("correoSocio", infoUser.correo, { path: "/" });
            cookie.set("idSocio", infoUser.idSocios, { path: "/" });
          }
        }
      } else {
        console.error("No se encontró el parámetro 'token' en la URL");
      }
    } catch (error) {
      console.error("Error durante la verificación:", error);
    } finally {
      // Establecer verificación como completa, ya sea éxitosa o no
      setVerificacionCompleta(true);
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      await realizarVerificacion();
      // El resto del código que deseas ejecutar después de la verificación

      if (!session && !cookie.get("sessionSocio")) {
        //console.log("entrando");
        // Solo redirigir si la verificación está completa
        setVerificacionCompleta(true);
      }
    };

    cargarDatos();
  }, []);

  if (!verificacionCompleta) {
    // Muestra un componente de carga o mensaje mientras la verificación está en curso
    return <div>Cargando...</div>;
  }

  if (!session && !cookie.get("sessionSocio")) {
    //console.log("entrando");
    return <Navigate to={path} />;
  }

  return <Outlet />;
};