//JPB 22-12-2023 Para coacreditado
import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { set, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import "../../App.css";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import { ruleCURP, ruleNumeroSeguridad } from "../../assets/validateRules";
import { obtEmpresaUrl } from "../../utils/global_functions";

export function InfoPersonalCoAcreditado() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const cookies = new Cookies();
  const [parentescoP2, setParentescoP2] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [idReferenciaProducto, setIdReferenciaProducto] = useState();
  const [idReferencia, setidReferencia] = useState();
  const [infoPersonal, setInfoPersonal] = useState({});
  const [infoReferenciasProductos, setInfoReferenciasProducto] = useState({});
  const [infoReferenciasProductos2, setInfoReferenciasProducto2] = useState({});
  const [infoReferencias, setInfoReferencias] = useState({});
  const [infoReferencias2, setInfoReferencias2] = useState({});
  const [infoBeneficiarios, setInfoBeneficiarios] = useState({});
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [validarReglaCurp, setValidarReglaCurp] = useState({});
  const [validarReglaNumeroSeguridad, setValidarReglaNumeroSeguridad] =
    useState({});

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    let data = await get("infoUserCoAcreditado", cookies.get("correo"));
    // console.log(data);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoPersonal(() => ({
        ...infoPersonal,
        ...data.user.infoPersonals[0],
      }));
    }
    //console.log(data.user.infoPersonals[0]);
  };

  const info2 = async () => {
    let data = await get(
      "getInfoReferenciasProductosCoAcreditado",
      cookies.get("id")
    );
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferenciasProducto(() => ({
        ...infoReferenciasProductos,
        ...idReferenciaProducto,
        ...data.infoReferencias[0],
      }));
      setInfoReferenciasProducto2(() => ({
        ...infoReferenciasProductos2,
        ...idReferenciaProducto,
        ...data.infoReferencias[1],
      }));
      // Add more fields as needed
    }
    //console.log(data.infoReferencias[0]);
  };

  // Familiar

  const info3 = async () => {
    let data = await get("getReferenciaEsCoacreditado", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferencias(() => ({
        ...infoReferencias,
        ...idReferencia,
        ...data.infoReferencias[0],
      }));
      setInfoReferencias2(() => ({
        ...infoReferencias2,
        ...idReferencia,
        ...data.infoReferencias[1],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  const info4 = async () => {
    let data = await get("getInfoBeneficiariosCoAcreditado", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoBeneficiarios(() => ({
        ...infoBeneficiarios,
        ...data.infoReferencias[0],
      }));
    }
    // console.log(data.infoReferencias[0]);
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    infoCalifica();
    info();
    info2();
    info3();
    info4();
  }, []);

  function miFuncion() {
    if (infoPersonal.curp !== "") {
      addInfoPersonal();
      addReferenciasProductos();
      addReferencia();
      addInfoBeneficiarios();
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Por favor llena todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  let dataInfoPersonal = {};
  let dataInfoReferenciasProductos = {};
  let dataInfoReferencia = {};
  let dataInfoBeneficiarios = {};
  let dataInfoBeneficiariosActualizar = {};

  const addInfoPersonal = async () => {
    dataInfoPersonal = {
      /*Parte 1*/
      idUser: cookies.get("id"),
      curp: infoPersonal.curp,
      sexo: infoPersonal.sexo,
      numeroSeguroSocial: infoPersonal.numeroSeguroSocial,
      estadoCivil: infoPersonal.estadoCivil,
      gradoMaxEstudios: infoPersonal.gradoMaxEstudios,
      numDepEco: infoPersonal.numDepEco,
      tipoVivienda: infoPersonal.tipoVivienda,
      aniosVivienda: infoPersonal.aniosVivienda,
      idResPrecalifica: idResPrecalifica,

      /*Parte 2*/
      sueldo: infoPersonal.sueldo,
      otroIngreso: infoPersonal.otroIngreso,
      autoValor: infoPersonal.autoValor,
      viviendoActualValor: infoPersonal.viviendoActualValor,
      escoAcreditado: true,
    };
    try {
      const existingInfo = await get(
        "getInfoPersonalCoAcreditado",
        cookies.get("id")
      );
      if (existingInfo.msg === "El usuario no tiene informacion personal") {
        // Usuario sin información, agregar nueva entrada
        const insertar = await post(
          "addInfoPersonalCoAcreditado",
          dataInfoPersonal
        );

        if (insertar.statuscode === 200) {
          // console.log("addInfo personal correcto");
        } else {
          // console.log("addInfo personal fallo");
        }
      } else {
        // Usuario con información, actualizar la existente
        const actualizar = await put("updateinfoPersonal", dataInfoPersonal);

        if (actualizar.statuscode === 200) {
          // console.log("updateInfo personal correcto");
        } else {
          // console.log("updateInfo personal fallo");
        }
      }
    } catch (error) {
      // console.error("Error durante la operación:", error);
    }
  };

  /** -------------------------------------------
                  Referencia Productos              
          --------------------------------------- **/

  const addReferenciasProductos = async () => {
    dataInfoReferenciasProductos = {
      idReferenciaProducto: infoReferenciasProductos.idReferenciaProducto,
      idResPrecalifica: idResPrecalifica,
      idUser: cookies.get("id"),
      tipoCuenta: infoReferenciasProductos.tipoCuenta,
      numeroCuenta: infoReferenciasProductos.numeroCuenta,
      institucionCredito: infoReferenciasProductos.institucionCredito,
      intitutoCapacitacion: infoReferenciasProductos.intitutoCapacitacion,
      tipoCuentaCapacitacion: infoReferenciasProductos.tipoCuentaCapacitacion,

      idReferenciaProducto2: infoReferenciasProductos2.idReferenciaProducto,
      idResPrecalifica2: idResPrecalifica, // Asigna un valor único
      idUser2: cookies.get("id"),
      tipoCuenta2: infoReferenciasProductos2.tipoCuenta,
      numeroCuenta2: infoReferenciasProductos2.numeroCuenta,
      institucionCredito2: infoReferenciasProductos2.institucionCredito,
      intitutoCapacitacion2: infoReferenciasProductos2.intitutoCapacitacion,
      tipoCuentaCapacitacion2: infoReferenciasProductos2.tipoCuentaCapacitacion,

      escoAcreditado: true,
    };

    let eleccionIU1 = await get(
      "getInfoReferenciasProductosCoAcreditado",
      cookies.get("id")
    );

    if (eleccionIU1.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addInfoReferenciasProductosCoAcreditado",
        dataInfoReferenciasProductos
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        console.log(
          `addInfoReferenciasProductosCoAcreditado correcto. IdResPrecalifica: ${idResPrecalifica}`
        );
      } else {
        // console.log("addInfoReferenciasProductosCoAcreditado fallo");
      }
    } else {
      if (eleccionIU1.statuscode === 200) {
        let actualizar = await put(
          "updateInfoReferenciasProductosCoAcreditado",
          dataInfoReferenciasProductos
        );

        if (actualizar.statuscode === 200) {
          // console.log("updateInfoReferenciasProductosCoAcreditado correcto");
        } else {
          // console.log("updateInfoReferenciasProductosCoAcreditado fallo");
        }
      }
    }
  };

  const addReferencia = async () => {
    dataInfoReferencia = {
      idReferencia: infoReferencias.idReferencia,
      idUser: cookies.get("id"),
      nombre: infoReferencias.nombre,
      aPaterno: infoReferencias.aPaterno,
      aMaterno: infoReferencias.aMaterno,
      relacion: infoReferencias.relacion,
      telefono: infoReferencias.telefono,
      email: infoReferencias.email,
      idResPrecalifica: idResPrecalifica,

      idReferencia2: infoReferencias2.idReferencia,
      idUser2: cookies.get("id"),
      nombre2: infoReferencias2.nombre,
      aPaterno2: infoReferencias2.aPaterno,
      aMaterno2: infoReferencias2.aMaterno,
      relacion2: infoReferencias2.relacion,
      telefono2: infoReferencias2.telefono,
      email2: infoReferencias2.email,
      idResPrecalifica2: idResPrecalifica,
      escoAcreditado: true,
    };

    let eleccionIU = await get(
      "getReferenciaEsCoacreditado",
      cookies.get("id")
    );
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addReferenciaCoAcreditado",
        dataInfoReferencia
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addReferenciaCoAcreditado correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addReferenciaCoAcreditado perosnal fallo");
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put(
          "updateReferenciaCoAcreditado",
          dataInfoReferencia
        );
        if (actualizar.statuscode === 200) {
          // console.log("updateReferenciaCoAcreditado correcto");
        } else {
          // console.log("updateReferenciaCoAcreditado fallo");
        }
      }
    }
  };

  const addInfoBeneficiarios = async () => {
    let eleccionIU = await get(
      "getInfoBeneficiariosCoAcreditado",
      cookies.get("id")
    );
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      dataInfoBeneficiarios = {
        idUser: cookies.get("id"),
        nombre: infoBeneficiarios.nombre,
        aPaterno: infoBeneficiarios.aPaterno,
        aMaterno: infoBeneficiarios.aMaterno,
        parentesco: infoBeneficiarios.parentesco,
        telefono: infoBeneficiarios.telefono,
        idResPrecalifica: idResPrecalifica,
        escoAcreditado: true,
      };
      let insertar = await post(
        "addInfoBeneficiariosCoAcreditado",
        dataInfoBeneficiarios
      );

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setTimeout(() => {
          window.location.href = "./InfocoAcreditado";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        dataInfoBeneficiariosActualizar = {
          idBeneficiario: eleccionIU.infoReferencias[0].idBeneficiario, //-
          idResPrecalifica: idResPrecalifica,
          nombre: infoBeneficiarios.nombre,
          idUser: cookies.get("id"),
          aPaterno: infoBeneficiarios.aPaterno,
          aMaterno: infoBeneficiarios.aMaterno,
          parentesco: infoBeneficiarios.parentesco,
          telefono: infoBeneficiarios.telefono,
          escoAcreditado: true,
        };
        let actualizar = await put(
          "updateInfoBeneficiariosCoAcreditado",
          dataInfoBeneficiariosActualizar
        );

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setTimeout(() => {
            window.location.href = "./InfocoAcreditado";
          }, 1000);
          // setTimeout(() => {
          //     window.location.href='./'
          // }, 1000);
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return Number(value).toLocaleString("es-MX");
    } else {
      return "0";
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/InfocoAcreditado">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Información Personal Coacreditado</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(miFuncion)}>
          <div className="Resum">
            <div className="header">
              <h3>Personales</h3>
            </div>

            <hr />

            <div className="cont_flex cont-form formInfoGeneral">
              {/* CURP */}
              <div className="col3 campo-form ">
                <label>CURP *</label>
                <input
                  type="text"
                  id="curp"
                  style={{ textTransform: "uppercase" }}
                  name="curp"
                  value={infoPersonal.curp}
                  maxLength={18}
                  onInput={(ev) => {
                    setValidarReglaCurp(ruleCURP(ev.target.value));
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      curp: ev.target.value,
                    }));
                  }}
                />
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.curp === "" ||
                  infoPersonal.curp === " " ||
                  infoPersonal.curp === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaCurp === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              {/* GENERO */}
              <div className="col3 campo-form estado marginEspacio label">
                <label>Género *</label>
                <select
                  name="sexo"
                  id="sexo"
                  value={infoPersonal.sexo}
                  onChange={(ev) => {
                    const isMale = ev.target.value === "true"; // Convertir a booleano
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      sexo: isMale,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="true">Masculino</option>
                  <option value="false">Femenino</option>
                </select>
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.sexo === "" ||
                  infoPersonal.sexo === " " ||
                  infoPersonal.sexo === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Número de seguridad social *</label>
                <input
                  type="tel"
                  id="numeroSeguroSocial"
                  name="numeroSeguroSocial"
                  maxLength={12}
                  value={infoPersonal.numeroSeguroSocial}
                  onInput={(ev) => {
                    setValidarReglaNumeroSeguridad(
                      ruleNumeroSeguridad(ev.target.value)
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numeroSeguroSocial: ev.target.value,
                    }));
                  }}
                ></input>
                <small>
                  <a
                    href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS;JSESSIONIDASEGEXTERNO=QVtxJ5QWWr9Y0NGI725WkKIErOaz4Rr8OtbSIHTlf91Hl7neHlaW!806955352"
                    target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
                    rel="noopener noreferrer" // Añadimos estos atributos de seguridad
                  >
                    Si no lo recuerdas, dar click aquí.
                  </a>
                </small>
                {(infoPersonal.numeroSeguroSocial === "" ||
                  infoPersonal.numeroSeguroSocial === " " ||
                  infoPersonal.numeroSeguroSocial === undefined ||
                  infoPersonal.numeroSeguroSocial === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaNumeroSeguridad === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Estado civil *</label>
                <select
                  name="estadoCivil>"
                  id="estadoCivil"
                  value={infoPersonal.estadoCivil}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      estadoCivil: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Soltero</option>
                  <option value="2">Unión libre</option>
                  <option value="3">Sociedad de Convivencia</option>
                  <option value="4">Casado (separación de Bienes)</option>
                  <option value="5">Casado (Sociedad Coyugal)</option>
                  <option value="6">Viudo</option>
                  <option value="7">Divorciado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Grado máximo de estudios *</label>
                <select
                  name="gradoMaxEstudios>"
                  id="gradoMaxEstudios"
                  value={infoPersonal.gradoMaxEstudios}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      gradoMaxEstudios: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Primaria</option>
                  <option value="2">Secundaria</option>
                  <option value="3">Preparatoria</option>
                  <option value="4">Técnico</option>
                  <option value="5">Licenciatura</option>
                  <option value="6">Postgrado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Número de dependientes económicos *</label>
                <select
                  name="numDepEco>"
                  id="numDepEco"
                  value={infoPersonal.numDepEco}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numDepEco: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6 o más</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Que tipo de vivienda habita? *</label>
                <select
                  name="tipoVivienda>"
                  id="tipoVivienda"
                  value={infoPersonal.tipoVivienda}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      tipoVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Propia</option>
                  <option value="2">Hipotecada</option>
                  <option value="3">Rentada</option>
                  <option value="4">De un familiar</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Años que a vivido en dicha vivienda? *</label>
                <select
                  name="aniosVivienda"
                  id="aniosVivienda"
                  value={infoPersonal.aniosVivienda}
                  onChange={(ev) => {
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      aniosVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  {/* Array para opciones */}
                  {Array.from({ length: 50 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias de Productos</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                          Referencia de crédito 1
               -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia de crédito 1</h3>
                  <input
                    id="idReferenciaProducto"
                    value={infoReferenciasProductos.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta *</label>
                  <select
                    name="tipoCuenta"
                    id="tipoCuenta"
                    value={infoReferenciasProductos.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="3">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito *</label>
                  <input
                    type="number"
                    id="numeroCuenta"
                    name="numeroCuenta"
                    value={infoReferenciasProductos.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito *</label>
                  <input
                    type="text"
                    id="institucionCredito"
                    name="institucionCredito"
                    value={infoReferenciasProductos.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta *</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion"
                    name="intitutoCapacitacion"
                    value={infoReferenciasProductos.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito *</label>
                    <select
                      name="tipoCuentaCapacitacion>"
                      id="tipoCuentaCapacitacion"
                      value={infoReferenciasProductos.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto(() => ({
                          ...infoReferenciasProductos,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }

                {/* ------------------------------------
                          Referencia de crédito 2
               -------------------------------------------*/}
                <div className="header">
                  <h3>Referencia de crédito 2</h3>
                  <input
                    id="idReferenciaProducto2"
                    value={infoReferenciasProductos2.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta 2</label>
                  <select
                    name="tipoCuenta2>"
                    id="tipoCuenta2"
                    value={infoReferenciasProductos2.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    // required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="3">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito</label>
                  <input
                    type="number"
                    id="numeroCuenta2"
                    name="numeroCuenta2"
                    value={infoReferenciasProductos2.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito</label>
                  <input
                    type="text"
                    id="institucionCredito2"
                    name="institucionCredito2"
                    value={infoReferenciasProductos2.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion2"
                    name="intitutoCapacitacion2"
                    value={infoReferenciasProductos2.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito</label>
                    <select
                      name="tipoCuentaCapacitacion2>"
                      id="tipoCuentaCapacitacion2"
                      value={infoReferenciasProductos2.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto2(() => ({
                          ...infoReferenciasProductos2,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      // required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                          Referencia Familiar
               -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia Familiar</h3>
                  <input
                    id="idReferencia"
                    name="idReferencia"
                    value={infoReferencias.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={infoReferencias.nombre}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno"
                    name="aPaterno"
                    value={infoReferencias.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno"
                    name="aMaterno"
                    value={infoReferencias.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion>"
                    id="relacion"
                    value={infoReferencias.relacion}
                    onChange={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Tel&eacute;fono *</label>
                  <input
                    type="tel"
                    id="telefono"
                    name="telefono"
                    value={infoReferencias.telefono}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={infoReferencias.email}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {/* ------------------------------------
                          Referencia No Familiar
               -------------------------------------------*/}

                <div className="header">
                  <h3>Referencia No Familiar </h3>
                  <input
                    id="idReferencia2"
                    name="idReferencia2"
                    value={infoReferencias2.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre2"
                    name="nombre2"
                    value={infoReferencias2.nombre}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno2"
                    name="aPaterno2"
                    value={infoReferencias2.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno2"
                    name="aMaterno2"
                    value={infoReferencias2.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion2>"
                    id="relacion2"
                    value={infoReferencias2.relacion}
                    onChange={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Teléfono *</label>
                  <input
                    type="number"
                    id="telefono2"
                    name="telefono2"
                    value={infoReferencias2.telefono}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="email"
                    id="email2"
                    name="email2"
                    value={infoReferencias2.email}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Ingresos y patrimonio</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="header">
                  <h3>Ingresos</h3>
                </div>
                <div className="col3 campo-form">
                  <label>Ingresos Mensuales Brutos *</label>
                  <input
                    type="text"
                    id="sueldo"
                    name="sueldo"
                    value={"$" + GetNF(infoPersonal.sueldo)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        sueldo: numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Otros ingresos *</label>
                  <input
                    type="text"
                    id="otroIngreso"
                    name="otroIngreso"
                    value={"$" + GetNF(infoPersonal.otroIngreso)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        otroIngreso: numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="header">
                  <h3>Patrimonio</h3>
                </div>
                <div className="col3 campo-form">
                  <label>Valor de Automoviles $$ *</label>
                  <input
                    type="text"
                    id="autoValor"
                    name="autoValor"
                    value={"$" + GetNF(infoPersonal.autoValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        autoValor: numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Valor de la vivienda Actual $$ *</label>
                  <input
                    type="text"
                    id="viviendoActualValor"
                    name="viviendoActualValor"
                    value={"$" + GetNF(infoPersonal.viviendoActualValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        viviendoActualValor: numericValue,
                      }));
                    }}
                  />
                </div>
              </form>
            </div>
          </div>

          {/* ------------Beneficiario-------------*/}

          <div className="Resum">
            <div className="header">
              <h3>Beneficiario</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={infoBeneficiarios.nombre}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno"
                    name="aPaterno"
                    value={infoBeneficiarios.aPaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido materno *</label>
                  <input
                    type="text"
                    id="aMaterno"
                    name="aMaterno"
                    value={infoBeneficiarios.aMaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="parentesco>"
                    id="parentesco"
                    value={infoBeneficiarios.parentesco}
                    onChange={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        parentesco: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form">
                  <label>Teléfono *</label>
                  <input
                    type="tel"
                    id="telefono"
                    name="telefono"
                    value={infoBeneficiarios.telefono}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <a
                className="btn btn_bco col3 campo-form "
                href="/InfocoAcreditado"
              >
                {" "}
                Regresar
              </a>
              {/*<a class="btn btn_morado col3 campo-form" onClick={() => {miFuncion()}} >Guardar</a>*/}
              <button className="btn btn_morado col3 campo-form" type="submit">
                Guardar
              </button>
            </div>
          </div>

          <div className="header">
            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>
        </form>
      </div>
    </>
  );
}
