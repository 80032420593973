import "../../../sass/style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { obtenerCotizaciones } from "../../../components/Funciones";
import { TablaComisiones } from "../../../components/Tablas";
import {
  MsjCantidadClientesRef,
  MsjCantidadComisiones,
  MsjTotalComision,
} from "../../../components/Msjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { obtEmpresaUrl } from "../../../utils/global_functions";

export default function PanelSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const idSocios = cookies.get("idSocio");
  const [dataComisiones, setDataComisiones] = useState("");

  useEffect(() => {
    obtenerCotizaciones(idSocios, setDataComisiones);
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, [idSocios]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1 className="titt">Mis comisiones</h1>
        <div class="cont_flexible">
          <div class="cont_left">
            <MsjCantidadClientesRef></MsjCantidadClientesRef>
            <MsjCantidadComisiones></MsjCantidadComisiones>
            <MsjTotalComision></MsjTotalComision>
          </div>
          <div class="cont_right">
            <TablaComisiones arrData={dataComisiones}></TablaComisiones>
          </div>
        </div>
      </div>
    </>
  );
}
