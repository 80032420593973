import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { get, post, put } from "../../utils/http";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import "../../App.css";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoFrmDatosExtra() {
  const cookies = new Cookies();

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [infoDatosExtras, setInfoDatosExtras] = useState({
    nombre: "",
    aPaterno: "",
    aMaterno: "",
    rfc: "",
    telefono: "",
    email: "",
  });

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    let data = await get("getInfoDatosExtras", cookies.get("id"));
    if (data.statuscode === 200) {
      setInfoDatosExtras(() => ({
        ...infoDatosExtras,
        ...data.infoDatosExtras[0],
      }));
    }
    // console.log(data.infoDatosExtras[0]);
  };

  let dataInfoDatosExtras;

  const addDatosExtra = async () => {
    dataInfoDatosExtras = {
      idUser: cookies.get("id"),
      idResPrecalifica: idResPrecalifica,
      nombre: infoDatosExtras.nombre,
      aPaterno: infoDatosExtras.aPaterno,
      aMaterno: infoDatosExtras.aMaterno,
      rfc: infoDatosExtras.rfc,
      email: infoDatosExtras.email,
      telefono: infoDatosExtras.telefono,
    };
    let eleccionIU = await get("getInfoDatosExtras", cookies.get("id"));
    if (eleccionIU.msg === "El usuario no tiene informacion de datos extras") {
      let insertar = await post("addInfoDatosExtras", dataInfoDatosExtras);
      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        // setTimeout(() => {
        //   window.location.href = "./InfocoAcreditado";
        // }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      console.log("antes de insertar", dataInfoDatosExtras);
      if (eleccionIU.statuscode === 200) {
        let actualizar = await put(
          "updateInfoDatosExtras",
          dataInfoDatosExtras
        );
        console.log("despues de insertar", dataInfoDatosExtras);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setTimeout(() => {
            window.location.href = "./InfocoAcreditado";
          }, 1000);
          // setTimeout(() => {
          //     window.location.href='./'
          // }, 1000);
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  const handleGuardarClick = async () => {
    await addDatosExtra();
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    infoCalifica();
    info();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/InfocoAcreditado">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Datos extras - Coacreditado</h1>
        <p>Aquí puedes guardar los datos que se piden</p>
        <div className="Resum">
          <div className="header">
            <h3>Datos Extras</h3>
          </div>
          <hr />
          <div className="cont_flex cont-form">
            {/* ------------------------------------
                          Datos Extra
               -------------------------------------------*/}

            <form onSubmit={handleSubmit(handleGuardarClick)}>
              <div className="col3 campo-form">
                <label>Nombre *</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={infoDatosExtras.nombre}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      nombre: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno *</label>
                <input
                  type="text"
                  id="aPaterno"
                  name="aPaterno"
                  value={infoDatosExtras.aPaterno}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      aPaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  id="aMaterno"
                  name="aMaterno"
                  value={infoDatosExtras.aMaterno}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      aMaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>RFC *</label>
                <input
                  type="text"
                  id="rfc"
                  name="rfc"
                  value={infoDatosExtras.rfc}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      rfc: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Teléfono *</label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={infoDatosExtras.telefono}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      telefono: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>E-mail *</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={infoDatosExtras.email}
                  onChange={(ev) => {
                    setInfoDatosExtras(() => ({
                      ...infoDatosExtras,
                      email: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="cont_flex cont-form">
                <div className="dosCuttonFormularios">
                  <button
                    type="button"
                    className="btn btn_bco col3 campo-form"
                    onClick={() =>
                      (window.location.href = "./InfocoAcreditado")
                    }
                  >
                    Regresar
                  </button>
                  <button
                    type="button"
                    className="btn btn_morado col3 campo-form"
                    onClick={handleGuardarClick}
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <div className="header">
                <h3>
                  Si realizaste algun cambio, recuerda <br />{" "}
                  <span className="morado"> guardar </span> antes de salir.
                </h3>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
