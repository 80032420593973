import { createContext,useContext,useState } from "react";
import React from 'react';

const dataPublic = createContext()
const setDataPublic = createContext()

export function useDataPublic(){return useContext(dataPublic)}
export function useSetDataPublic(){return useContext(setDataPublic)}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------- Declaración de variables públicas  ---------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function PublicProvider({children}){
    const [data,setData] = useState('')

    const changeDataPublic = ({monto =null, tasa = null, enganche = null, valor=null, plazoVal=null, montoPrestar=null, fechaCrecion=null, mensualidad=null, ibm= null, nombre = null,ap = null,am = null,correo = null, buroId = null, idUser =null, rango =null, statuscode = null, cuestionarioId = null, bancoEscogido = null, idResPaso1 = null}) => {
        setData({monto:monto, tasa:tasa, enganche:enganche, valor: valor, plazoVal:plazoVal, montoPrestar:montoPrestar, fechaCrecion:fechaCrecion, mensualidad:mensualidad, ibm:ibm, nombre:nombre,ap:ap,am:am,correo:correo, buroId:buroId, idUser:idUser, rango:rango, statuscode: statuscode, cuestionarioId: cuestionarioId, bancoEscogido: bancoEscogido, idResPaso1: idResPaso1})
    }
    
    return(
        <dataPublic.Provider value={data}>
            <setDataPublic.Provider value={changeDataPublic}>
                {children}
            </setDataPublic.Provider>
        </dataPublic.Provider>
    )
}
