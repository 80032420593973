import "../App.css";
import "../sass/style.css";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { obtenerClientes } from "./Funciones";
import { Link } from "react-router-dom";
import ObtUsuarioRemaxComponent from "./Funciones";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Menú lateral cuando el usuario esta logueado ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function NavHome({ active }) {
  const [mostrarBotonHomeSocios, setMostrarBotonHomeSocios] = useState(true);
  const cookies = new Cookies();
  const [dataClientes, setDataClientes] = useState("");
  const idSocios = cookies.get("idSocio");

  useEffect(() => {
    obtenerClientes(idSocios, setDataClientes);
  }, [idSocios]);

  return (
    <>
      <ObtUsuarioRemaxComponent
        idSocios={idSocios}
        setMostrarBotonHomeSocios={setMostrarBotonHomeSocios}
      />

      <div className=" menu_lateral">
        <ul>
          {mostrarBotonHomeSocios && (
            <li className="temporalFondo">
              <Link
                to="/Home_Socios"
                className={active === "03" ? "active" : ""}
              >
                <i className="fa fa-user"></i> Home Socios
              </Link>
            </li>
          )}
          <li>
            <Link to="/panelSocios" className={active === "01" ? "active" : ""}>
              <i className="fa fa-user"></i> Mis Clientes
            </Link>
          </li>
          <li>
            <Link
              to="/invitacionesSocios"
              className={active === "02" ? "active" : ""}
            >
              <i className="fa fa-envelope"></i> Invitaciones
            </Link>
          </li>

          {mostrarBotonHomeSocios && (
            <li className="temporalFondo">
              <Link
                to="/misComisiones"
                className={active === "04" ? "active" : ""}
              >
                <i className="fa fa-user"></i> Mis comisiones
              </Link>
            </li>
          )}
        </ul>
        <div className="copyright">
          <p>
            En caso de que tengas preguntas, dudas o necesites asesoría no dudes
            en contactarnos. Nos encanta atenderte a la hora que lo necesites
          </p>
          <li>
            {" "}
            <Link to="/AvisoPrivacidadLogueado" className="link">
              Aviso de privacidad
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/TerminosCondicionesLogueado" className="link">
              Términos y condiciones
            </Link>
          </li>
          <p>© 2024. Saturn5 Technologies SAPI de CV</p>
        </div>
      </div>
    </>
  );
}
