import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, put, post } from "../../utils/http";
import {
  BotonDescarga,
  InfoCotizacion,
  PasosAseguir,
} from "../../components/Cards";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { TarjetaSubirDocumentacion } from "../../components/Cards";
import { TarjetaCalculoDias } from "../../components/Cards";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import { TarjetaFormalizacion } from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import imagen from "../../images/icono.png";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function FormalizacionFirma() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 6, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");
      // let nombrePantalla =
      //   credito > 0 ? "7.Cierre y formalización" : "6.Cierre y formalización";

      // let pantallaActualMenu = (
      //   <PasosAseguir
      //     nombrePantalla={nombrePantalla}
      //     urlActual={urlActual}
      //     credito={credito}
      //   />
      // );
      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = credito > 0 ? "7." : "6.";
      let descripcionPaso = "Formalización y Firma";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    console.log(pantallasFlujoBanco[idPantallaUrlActual].paso);
    console.log(pantallasFlujoBanco[idPantallaActual].paso);
    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  const [formalizacionFirma, setFormalizacionFirma] = useState({});

  //Obtenemos información general
  const obtInformacion = async () => {
    //Parametro fijo hasta que determinemos como se obtiene
    //let data = await get('getFormalizacionFirma', 294);
    let data = await get("getFormalizacionFirma", idResPrecalifica);
    // console.log(data);
    if (data.statuscode === 200) {
      setFormalizacionFirma(() => ({
        ...formalizacionFirma,
        ...data.formalizacionFirma[0],
      }));
    }
  };

  const siguiente = async () => {
    await guardarPaso(7, idCuestionarioSolicitud, idResPrecalifica);
    window.location.href = "/felicidades";
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    obtPantallaActual();
  }, []);

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return value.toLocaleString("es-MX", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  //Formatear fecha a una fecha legible
  const formatDate = (isoDate) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Date(isoDate).toLocaleString("es-MX", options);
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>
        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Formalizaci&oacute;n y Firma</h1>
        <p>
          Estamos en la &uacute;ltima etapa, tendr&aacute;s que revisar el
          documento y programar la fecha para poder ir a la notar&iacute;a y
          ¡hacerte dueño de tu casa!
        </p>

        <h3 className="titt">Notas</h3>
        <hr />

        <div id="notas"></div>
        <br />

        <div className="cont_flex con_flex_wrap tabla_valores">
          <div className="cont_loop">
            <TarjetaFormalizacion
              titulo="Valor de propiedad"
              valor={"$" + GetNF(formalizacionFirma.valorPropiedad) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Impuestos sobre adquisición de inmuebles"
              valor={"$" + GetNF(formalizacionFirma.impuestoAi) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Gastos de registro"
              valor={"$" + GetNF(formalizacionFirma.gastoRegistro) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Honorarios notaria"
              valor={"$" + GetNF(formalizacionFirma.honorarioNotaria) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Total gastos escrituración"
              valor={
                "$" + GetNF(formalizacionFirma.totalGastoEscrituracion) + " MXN"
              }
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Costo total de la operación"
              valor={"$" + GetNF(formalizacionFirma.totalOperacion) + " MXN"}
            ></TarjetaFormalizacion>
          </div>
          <div className="cont_loop">
            <TarjetaFormalizacion
              titulo="Enganche"
              valor={"$" + GetNF(formalizacionFirma.enganche) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Valor del credito"
              valor={"$" + GetNF(formalizacionFirma.valorCredito) + " MXN"}
            ></TarjetaFormalizacion>
            <TarjetaFormalizacion
              titulo="Total"
              valor={"$" + GetNF(formalizacionFirma.total) + " MXN"}
            ></TarjetaFormalizacion>
          </div>
        </div>

        <hr />

        <div className="Resum">
          <div className="header">
            <h3>Firma</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Fecha de solicitud de banco</label>
                <input
                  type="text"
                  value={formatDate(formalizacionFirma.fechaSolicitudBanco)}
                />
              </div>

              <div className="col3 campo-form">
                <label>Fecha de envio a notaria</label>
                <input
                  type="text"
                  value={formatDate(formalizacionFirma.fechaEnvioNotaria)}
                />
              </div>

              <div className="col3 campo-form">
                <label>Fecha de firma </label>
                <input
                  type="text"
                  value={formatDate(formalizacionFirma.fechaFirma)}
                />
              </div>

              <div className="col3 campo-form">
                <label>Confirmaci&oacute;n de fecha </label>
                <input
                  type="text"
                  value={formatDate(formalizacionFirma.fechaConfirmacion)}
                />
              </div>

              <div className="col3 campo-form">
                <label>Hora </label>
                <input type="text" value={formalizacionFirma.hora} />
              </div>

              <div className="col3 campo-form">
                <label>Monto a firmar </label>
                <input
                  type="text"
                  value={GetNF(formalizacionFirma.montoFirmar)}
                />
              </div>

              <div className="col3 campo-form inputGrande">
                <label>Lugar </label>
                <input type="text" value={formalizacionFirma.lugar} />
              </div>
            </form>
          </div>
        </div>

        <div className="cont_loop descarga">
          <img src={imagen} />
          <h3>Descarga el proyecto de escritura y leelo detenidamente</h3>
          <BotonDescarga
            idResPrecalifica={idResPrecalifica}
            idCuestionario={idCuestionarioSolicitud}
          ></BotonDescarga>
        </div>
        
        {
          /* 
            <a href="/notariaAsignada" className="btn btn_big">
              Regresar
            </a>

            <a
              className="btn btn btn_big btn_morado"
              onClick={() => {
                siguiente();
              }}
            >
              Avanzar
            </a>
          */
        }

      </div>
    </>
  );
}
